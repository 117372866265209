import React, { useState, JSX } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "helpers/useTitle";
import { useQuery, useMutation } from "@apollo/client";

import {
  ONEPAGER_QUERY,
  EDIT_ONEPAGER_MUTATION,
  PUBLISH_ONEPAGER_MUTATION,
} from "queries/onepager";
import Layout from "layout/layout";

import { Publications } from "../publications";

import { FaLongArrowAltLeft } from "react-icons/fa";

import { EditOnepagerModal } from "components/editVersioned/editOnepager";
import { stripUnknownValuesBeforeSave } from "components/editVersioned/genericForm";
import { Link, useSearch, useLocation } from "wouter";

import { Onepager, Block, InputBlock } from "generated/graphql";
import { BlockComponent, Components } from "../blocks";
import { Sidebar } from "./sidebar";
import { AddBlock } from "../blocks/addBlock";
import { LoadingSpinner } from "components/loadingSpinner";

// In order to use polymorphic embedded we need to wrap every block in an object with a type property
const buildBlockInput = (b, formDefinition) => {
  const sendingValues = stripUnknownValuesBeforeSave({
    values: b,
    formDefinition,
  });

  const { isReadyForReview, ...rest } = sendingValues;

  return {
    type: b.type,
    [b.type]: rest,
  };
};

const Content = ({
  onepager,
  setShowEditAttributeModal,
  setShowPublications,
}: {
  onepager: Partial<Onepager>;
  setShowEditAttributeModal: (open: boolean) => void;
  setShowPublications: (open: boolean) => void;
}) => {
  const [editBlocksMutation] = useMutation(EDIT_ONEPAGER_MUTATION);

  const [publishMutation] = useMutation(PUBLISH_ONEPAGER_MUTATION);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletedId, setDeletedId] = useState<string>();

  const blocks = onepager?.blocks ?? [];

  // Since the new draft have a different id,
  // Remove the current draft from the apollo cache
  // This removes the "flash" you see after updating the draft
  const setBlocks = (blocks: (Block | InputBlock)[]) => {
    const inputBlocks = blocks.map((block) => {
      const formDefinition = block?.type
        ? Components[block.type as keyof typeof Components].formDefinition
        : null;
      return buildBlockInput(block, formDefinition);
    });

    return editBlocksMutation({
      variables: {
        id: onepager.id,
        fields: { blocks: inputBlocks },
      },
      update(cache) {
        // remove from apollo cache
        const id = cache.identify({
          __typename: "Onepager",
          id: onepager.id,
        });
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  const publish = (pageId: string) => {
    return publishMutation({
      variables: { id: pageId },
      refetchQueries: ["GetOnepager"],
    });
  };

  const addBlock = (newBlock: InputBlock, i: number) => {
    setSelectedIndex(i);
    setIsLoading(true);
    setBlocks([
      ...blocks.slice(0, i),
      newBlock,
      ...blocks.slice(i, blocks.length),
    ]).finally(() => setIsLoading(false));
  };

  const deleteBlock = (id: string) => {
    setDeletedId(id);
    setIsDeleting(true);
    setBlocks(blocks.filter((b) => b.id !== id)).finally(() =>
      setIsDeleting(false),
    );
  };

  const moveUpBlock = (i: number) => {
    setBlocks([
      ...blocks.slice(0, i - 1),
      blocks[i],
      blocks[i - 1],
      ...blocks.slice(i + 1, blocks.length),
    ]);
  };

  const moveDownBlock = (i: number) => {
    setBlocks([
      ...blocks.slice(0, i),
      blocks[i + 1],
      blocks[i],
      ...blocks.slice(i + 2, blocks.length),
    ]);
  };

  const updateBlock = (newBlock: Block) => {
    const newBlocks = blocks.map((block) => {
      if (block.id === newBlock.id) {
        return { ...newBlock };
      } else {
        return block;
      }
    });
    return setBlocks(newBlocks);
  };

  // At first, the onepager was designed to be multilang,
  // but it turned out that it works better being in one language only.
  // Because of this, we hardcode the language to "de" and store everything there.
  const fallbackLanguage = "de";
  const supportedLanguage =
    onepager.supportedLanguages?.[0] ?? fallbackLanguage;

  return (
    <div className="flex flex-col-reverse sm:flex-row ">
      <div className="m-3 sm:w-3/4 sm:py-8">
        <div className="mx-auto max-w-screen sm:max-w-sm md:max-w-md lg:max-w-lg">
          <div className="space-y-6">
            {selectedIndex == 0 && isLoading ? (
              <LoadingSpinner />
            ) : (
              <AddBlock
                index={0}
                addBlock={addBlock}
                supportedLanguage={supportedLanguage}
              />
            )}
            {blocks?.map((block: Block, i) => {
              return (
                <div key={block.id} className="space-y-6">
                  <div className="relative z-0">
                    <BlockComponent
                      block={block}
                      index={i}
                      supportedLanguage={supportedLanguage}
                      language={fallbackLanguage}
                      updateBlock={updateBlock}
                      deleteBlock={deleteBlock}
                      moveUpBlock={moveUpBlock}
                      moveDownBlock={moveDownBlock}
                    />
                    {isDeleting && deletedId == block.id && (
                      <div className="absolute inset-0 z-10 flex items-center justify-center">
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>

                  {selectedIndex == i + 1 && isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <AddBlock
                      index={i + 1}
                      addBlock={addBlock}
                      supportedLanguage={supportedLanguage}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Sidebar
        onepager={onepager}
        publish={publish}
        setShowEditAttributeModal={setShowEditAttributeModal}
        setShowPublications={setShowPublications}
      />
    </div>
  );
};

const OnepagerTitle = ({ visibleOnepager }) => {
  const { t } = useTranslation("onepager");

  return (
    <div className="sticky top-12 z-20 bg-white shadow">
      <div className="flex flex-col items-center justify-between py-6 sm:flex-row sm:px-3 sm:mx-auto sm:max-w-screen-tbf">
        <div className="flex flex-col">
          <div className="text-3xl font-medium text-red-500 ">
            {visibleOnepager?.title}
          </div>
          <div className="flex flex-row mt-3 flex-start">
            <FaLongArrowAltLeft className="mr-1 text-sm leading-4 text-blue-500" />
            <Link
              className="text-sm leading-4 text-blue-500 cursor-pointer hover:text-gray-350"
              href={"/onepagers/all"}
            >
              {t("backToAll")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OnepagerDetail = ({ id }: { id: string }): JSX.Element => {
  const { t } = useTranslation(["onepager", "common"]);
  const [location, navigate] = useLocation();
  const query = useSearch();
  const params = new URLSearchParams(query);
  const openSettingsParam = params.get("openSettings");
  const { data, loading, error } = useQuery(ONEPAGER_QUERY, {
    variables: { id },
  });
  const onepager: Onepager | undefined = data?.["onepager"];
  const visibleOnepager = onepager;

  useTitle(`${visibleOnepager?.title ?? "untitled"} - TBF Onepager-Tool`);

  const [showEditAttributeModal, setShowEditAttributeModal] = useState(
    openSettingsParam === "true",
  );
  const [showPublications, setShowPublications] = useState(false);

  if (loading && !onepager) {
    return <Layout loading={loading} />;
  } else if (!onepager) {
    return (
      <Layout title={t("common:warning")}>
        <div>
          <pre>{JSON.stringify({ data, error }, null, 2)}</pre>
        </div>
      </Layout>
    );
  } else {
    return (
      <>
        <Layout loading={loading} noPadding />
        <OnepagerTitle visibleOnepager={visibleOnepager} />
        <div className="relative px-3 mx-auto max-w-screen-tbf">
          <Content
            onepager={onepager}
            setShowEditAttributeModal={setShowEditAttributeModal}
            setShowPublications={setShowPublications}
          />
        </div>
        <EditOnepagerModal
          id={onepager.id}
          open={showEditAttributeModal}
          close={() => {
            navigate(location, { replace: true });
            setShowEditAttributeModal(false);
          }}
        />
        {showPublications ? (
          <Publications
            isOpen={showPublications}
            id={onepager.id}
            setIsOpen={setShowPublications}
          />
        ) : null}
      </>
    );
  }
};
