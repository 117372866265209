import React, { JSX } from "react";
import { useTranslation } from "react-i18next";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { ListField } from "./ListField";
import { TextField } from "./textField";
import { MultiLang } from "helpers/ui/multiLang";
import { extractMulti, stringifyMulti } from "helpers/ui/multiLang";

type LinkType = {
  id?: string;
  name: MultiLang;
  url: string;
};
type LinksType = LinkType[];

export type LinksFieldProps = GenericFieldProps<LinksType>;

// HELPER COMPONENT

const Link = ({ value, disabled, onChange, language }) => {
  const { t } = useTranslation();

  const handleNameChange = (newValue) => {
    const newLink = {
      ...value,
      name: { ...value.name, [language]: newValue },
    };
    onChange(newLink);
  };

  const handleUrlChange = (newValue) => {
    const newLink = {
      ...value,
      url: newValue,
    };
    onChange(newLink);
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="flex-1 text-sm">
        <TextField
          placeholder={t("name")}
          disabled={disabled}
          onChange={handleNameChange}
          value={value.name?.[language] ?? ""}
          language={language}
        />
      </div>
      <div className="flex-1 text-sm">
        <TextField
          placeholder={t("url")}
          disabled={disabled}
          onChange={handleUrlChange}
          value={value.url ?? ""}
        />
      </div>
    </div>
  );
};

export const LinksField = ({
  value,
  onChange,
  language,
  disabled,
}: LinksFieldProps): JSX.Element => {
  const newLink = {
    name: { de: "", fr: "", it: "", en: "" },
    url: "",
    key: "new",
  };

  return (
    <ListField
      value={(value ?? []).map((item, i) => ({
        ...item,
        key: item.id || `${i}`,
      }))}
      language={language}
      onChange={onChange}
      disabled={disabled}
      render={Link}
      newItem={newLink}
      catalogue={[]}
    />
  );
};

export const linksField: GenericField<LinksFieldProps> = {
  renderer: LinksField,
  onBeforeSave: (value) =>
    value?.map((v) => ({
      name: extractMulti(v.name),
      url: v.url,
    })),
  equals: (a, b) => {
    return (
      (a?.map((v) => `${stringifyMulti(v.name)}-${v.url}`).join(",") ?? "") ===
      (b?.map((v) => `${stringifyMulti(v.name)}-${v.url}`).join(",") ?? "")
    );
  },
};
