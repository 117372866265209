import React, { useMemo, JSX } from "react";
import SimpleMDE from "react-simplemde-editor";
import { useTranslation } from "react-i18next";

// MAIN COMPONENT

const wordCount = (data) => {
  if (!data || !data.match) {
    return 0;
  }
  // Source: https://github.com/sparksuite/simplemde-markdown-editor/blob/master/src/js/simplemde.js#L1053
  const pattern =
    /[a-zA-Z0-9_\u0392-\u03c9\u0410-\u04F9]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/g;
  const m = data.match(pattern);
  let count = 0;
  if (m === null) {
    return count;
  }
  for (const v of m) {
    if (v.charCodeAt(0) >= 0x4e00) {
      count += v.length;
    } else {
      count += 1;
    }
  }
  return count;
};

const charCount = (data) => {
  return data?.length ?? 0;
};

type SimpleMDEWordCountProps = {
  maxWordCount: number;
  maxCharCount: number;
  value: string;
  onChange: any;
} & { [key: string]: any };

export const SimpleMDEWordCount = ({
  maxWordCount,
  maxCharCount,
  value,
  onChange,
  options,
  events,
}: SimpleMDEWordCountProps): JSX.Element => {
  const { t } = useTranslation("employee");
  const words = maxWordCount ? wordCount(value ?? "") : 0;
  const chars = maxCharCount ? charCount(value ?? "") : 0;
  const invalid = words > maxWordCount || chars > maxCharCount;

  const simpleMDEOptions = useMemo(() => options, []);

  return (
    <>
      <SimpleMDE
        value={value}
        onChange={onChange}
        options={simpleMDEOptions}
        events={events}
      />
      {maxWordCount ? (
        <span
          className={`text-xs float-right ${
            invalid ? "text-red-500" : ""
          } mt-1`}
        >
          {t("wordCount", { wordCount: words, maxWordCount })}
        </span>
      ) : null}
      {maxCharCount ? (
        <span
          className={`text-xs float-right ${
            invalid ? "text-red-500" : ""
          } mt-1`}
        >
          {t("charCount", { charCount: chars, maxCharCount })}
        </span>
      ) : null}
    </>
  );
};
