import React, { useContext, useState, JSX } from "react";
import { GenericField, GenericFieldProps } from "./fieldProps";
import { MdFilterCenterFocus } from "react-icons/md";

import { BlockPicture } from "generated/graphql";
import { InputFile } from "components/inputFile";
import { IconButton } from "components/button";
import { useTranslation } from "react-i18next";
import { FocusModal } from "./picture/focusModal";
import { FaTrashAlt } from "react-icons/fa";
import { LoadingSpinner } from "components/loadingSpinner";
import { getApiUrl } from "helpers/apiUrl";
import AuthContext from "context/user";

type BlockPictureExtended = BlockPicture & { action: string };

export type PictureFieldProps = GenericFieldProps<BlockPictureExtended | null>;

export const PictureField: React.FC<PictureFieldProps> = ({
  value,
  onChange,
  hideFocusPoint = false,
  disabled = false,
}) => {
  const { t } = useTranslation(["onepager", "project"]);
  const { token } = useContext(AuthContext);

  const [showEditFocus, setShowEditFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleOnChange = (file) => {
    const formData = new FormData();
    formData.append("image", file);

    setIsLoading(true);
    setImageError(false);

    fetch(`${getApiUrl()}/api/onepager-block-picture/uploadFile`, {
      method: "post",
      body: formData,
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        onChange?.({
          id: data.id,
          url: data.url,
          filename: file.name,
          action: "update",
          focusX: 0,
          focusY: 0,
        });
      })
      .catch(() => {
        setImageError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {value?.url ? null : (
            <div className="flex flex-col items-center space-y-2">
              <InputFile
                disabled={disabled}
                title={t("project:addImage")}
                // If you make changes to this list, you also need to change the server validation in `block_picture.ex`
                accept="image/jpeg, image/png, image/gif, image/png"
                onChange={handleOnChange}
              />
              {imageError ? <ImageErrorWarning /> : null}
            </div>
          )}
          {value?.url ? (
            <div className="pl-2">
              <div className="flex items-center justify-between w-full">
                <img src={value.url} className="max-w-md" />
              </div>
              {value?.filename && (
                <div className="flex flex-row max-w-md mt-2 truncate">
                  <div className="pr-2 text-sm">
                    {t("onepager:jokerBlock.selectedImage")}:
                  </div>
                  <div className="text-sm truncate">{value.filename}</div>
                </div>
              )}
              {imageError ? <ImageErrorWarning /> : null}
              <div className="flex flex-row flex-wrap pt-2 gap-x-2 gap-y-2">
                <InputFile
                  disabled={disabled}
                  title={t("onepager:jokerBlock.replaceImage")}
                  // If you make changes to this list, you also need to change the server validation in `block_picture.ex`
                  accept="image/jpeg, image/png, image/gif, image/png"
                  onChange={handleOnChange}
                  showFilename={false}
                />
                <IconButton
                  type="warning"
                  Icon={FaTrashAlt}
                  disabled={disabled}
                  onClick={() => {
                    onChange?.({ action: "unlink" });
                  }}
                >
                  {t("onepager:jokerBlock.deleteImage")}
                </IconButton>
                {hideFocusPoint ? null : (
                  <IconButton
                    Icon={MdFilterCenterFocus}
                    disabled={disabled}
                    onClick={() => {
                      setShowEditFocus(true);
                    }}
                  >
                    {t("onepager:jokerBlock.focusPoint")}
                  </IconButton>
                )}
              </div>
              {showEditFocus ? (
                <FocusModal
                  imageSrc={value.url}
                  focus={{ x: value?.focusX ?? 0, y: value?.focusY ?? 0 }}
                  setFocus={(newFocus) => {
                    onChange?.({
                      ...value,
                      action: value?.action ? value.action : "update",
                      focusX: newFocus.x,
                      focusY: newFocus.y,
                    });
                  }}
                  close={() => setShowEditFocus(false)}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export const blockPictureField: GenericField<PictureFieldProps> = {
  renderer: PictureField,
  onBeforeSave: (picture) =>
    picture?.action
      ? {
          url: picture.url,
          action: picture.action,
          filename: picture.filename,
          focusX: picture.focusX,
          focusY: picture.focusY,
          id: picture?.id,
        }
      : { action: "noaction", id: picture?.id },
  equals: (a, b) => (a?.url ?? "") === (b?.url ?? ""),
};

export const ImageErrorWarning = (): JSX.Element => {
  const { t } = useTranslation("error");

  return (
    <div className="max-w-md p-2 mt-1 text-sm border border-red-400 rounded bg-red-50">
      <div className="font-bold">{t("imageErrorTitle")}</div>
      <div>{t("imageErrorDescription")}</div>
    </div>
  );
};
