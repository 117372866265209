import React, { useState, JSX } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GenericFieldProps } from "./fieldProps";
import { customStyles, i18nOptions } from "./multiSelectField";

import { ALL_PROJECTS_QUERY } from "queries/newProject";
import { useMultiLang } from "helpers/multiLang";

import { formatProjectId } from "pages/newProject/header";
import Select from "react-select";
import { useFuse } from "helpers/useFuse";

export type ProjectsFieldProps = GenericFieldProps<string>;

export const ProjectsField = ({
  value,
  onChange,
  disabled,
  entity,
  multiple = true,
}: ProjectsFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const m = useMultiLang();
  const { data } = useQuery(ALL_PROJECTS_QUERY);
  const projects = data?.newProjects ?? [];

  const optionMap = (v) => ({
    value: v.id,
    label: `${formatProjectId(v.abacusProjectId ?? "")} - ${m(v.name)}`,
  });

  const selectedOptions = projects?.filter((c) =>
    multiple ? value?.includes(c.id) : value === c.id,
  );
  const ownProjectFilter = (p) => p.id !== entity?.id;
  const preparedProjects = projects?.filter(ownProjectFilter)?.map(optionMap);

  const [inputValue, setInputValue] = useState("");

  const filterProjects = useFuse(preparedProjects, inputValue, {
    keys: ["label"],
  })?.slice(0, 200);

  return (
    <div className="text-sm">
      <Select
        onInputChange={(value) => setInputValue(value)}
        isMulti={multiple ?? true}
        isClearable={true}
        styles={customStyles}
        {...i18nOptions(t)}
        options={filterProjects}
        isDisabled={disabled}
        value={selectedOptions?.map(optionMap)}
        onChange={
          onChange &&
          ((newValues: any) => {
            if (multiple ?? true) {
              onChange((newValues as any).map((v) => v.value));
            } else {
              onChange(newValues?.value ?? null);
            }
          })
        }
      />
    </div>
  );
};

export const projectsField = {
  renderer: ProjectsField,
  equals: (a, b) => {
    return (a?.join(",") ?? "") === (b?.join(",") ?? "");
  },
};
