import React, { useEffect, JSX } from "react";
import { useQuery } from "@apollo/client";

import { EMPLOYEE_QUERY } from "queries/newEmployee";

import EmployeeDisplay from "./employee";
import { useLocation } from "wouter";

const NewEmployeeShell = ({
  id,
  activeDirectoryId,
  abacusId,
  employeeNumber,
  tab,
}: {
  id?: string;
  activeDirectoryId?: string;
  abacusId?: string;
  employeeNumber?: string;
  tab?: string;
}): JSX.Element => {
  const { data, loading } = useQuery(EMPLOYEE_QUERY, {
    variables: { id, activeDirectoryId, abacusId, employeeNumber },
  });

  const [_location, navigate] = useLocation();
  const params: { tab?: string } = { tab };
  const loadedId = id ?? data?.employee?.id;

  useEffect(() => {
    if (!id && (activeDirectoryId || abacusId || employeeNumber) && loadedId) {
      const tab = params.tab ? `/${params.tab}` : "";
      navigate(`/employee/${loadedId}${tab}`, { replace: true });
    }
  }, [loadedId]);

  return (
    <EmployeeDisplay
      id={loadedId}
      activeDirectoryId={activeDirectoryId}
      employee={data?.employee}
      loading={loading}
      currentEmployee={data?.me}
      tab={tab}
    />
  );
};

export default NewEmployeeShell;
