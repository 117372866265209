import React, { JSX } from "react";
import { useSearch } from "wouter";

import { MainVersion } from "./MainVersion";
import { PreviousVersion } from "./PreviousVersion";

export const Wiki = ({
  slugsPath,
  id,
}: {
  slugsPath?: string;
  id?: string;
}): JSX.Element => {
  const query = useSearch();
  const params = new URLSearchParams(query);
  const slugs = slugsPath
    ? slugsPath.split("/").filter((e) => e !== "")
    : undefined;

  if (params.get("version")) {
    const versionNumber = params.get("version");
    // Display previous version of the specified wiki page
    return <PreviousVersion slugs={slugs} version={versionNumber} />;
  } else {
    // Display current version of the specified wiki page
    return <MainVersion slugs={slugs} id={id} />;
  }
};
