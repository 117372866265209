import React, { JSX } from "react";

export const LargeSelectionWrapper = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle: string;
  children: any;
}): JSX.Element => {
  return (
    <div className="px-8 m-4">
      <div className="flex-1">
        <div className="font-medium whitespace-pre-line">{title}</div>
        <div className="text-xs text-gray-500 whitespace-pre-line">
          {subtitle}
        </div>
      </div>
      <div className="flex flex-row flex-wrap cursor-pointer pt-3">
        {children}
      </div>
    </div>
  );
};
