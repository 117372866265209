import React, { useEffect, JSX } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { customStyles, i18nOptions } from "./multiSelectField";

import { GenericFieldProps } from "./fieldProps";

type OptionInputType = {
  value: string;
  labelKey: string;
};

export type SingleSelectFieldProps = GenericFieldProps<string> & {
  options: OptionInputType[];
};

export const SingleSelectField = ({
  value,
  onChange,
  disabled,
  options,
  defaultValue,
}: SingleSelectFieldProps): JSX.Element => {
  const { t } = useTranslation();

  const optionMap = (v: OptionInputType) => ({
    value: v.value,
    label: t(v.labelKey as any),
  });

  useEffect(() => {
    if (defaultValue && onChange) {
      onChange(defaultValue);
    }
  }, []);

  const selectedOption = options.find(
    (o) => o.value === (value ?? defaultValue),
  );

  return (
    <div className="text-sm">
      <Select
        styles={customStyles}
        options={options.map(optionMap)}
        {...i18nOptions(t)}
        isClearable
        isDisabled={disabled}
        value={
          selectedOption?.value
            ? {
                value: selectedOption?.value,
                label: t(selectedOption?.labelKey ?? "common:loading"),
              }
            : null
        }
        onChange={
          onChange &&
          ((newValue: any) => {
            onChange(newValue?.value || null);
          })
        }
      />
    </div>
  );
};

export const singleSelectField = {
  renderer: SingleSelectField,
};

export type SingleSelectFromArrayFieldProps = GenericFieldProps<string[]> & {
  options: OptionInputType[];
};

// This is a variant of the single select field that is backed by an array on the backend.
// This is useful for fields that were arrays in the past, but have changed to be a single select later.
export const SingleSelectFromArrayField = ({
  value,
  onChange,
  disabled,
  options,
  defaultValue,
}: SingleSelectFromArrayFieldProps): JSX.Element => {
  return (
    <SingleSelectField
      value={value?.[0]}
      defaultValue={defaultValue}
      onChange={(newValue) => onChange(newValue ? [newValue] : [])}
      disabled={disabled}
      options={options}
    />
  );
};

export const singleSelectFromArrayField = {
  renderer: SingleSelectFromArrayField,
};
