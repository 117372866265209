import { useState } from "react";
import * as docx from "docx";
import {
  Paragraph,
  TextRun,
  Table,
  TableOfContents,
  IParagraphOptions,
  INumberingOptions,
  LevelFormat,
  ImageRun,
  TabStopType,
  TabStopPosition,
  PageNumber,
  convertMillimetersToTwip,
} from "docx";

import { getTextFromMultiLang, useMultiLang } from "helpers/multiLang";
import {
  pageBreak,
  textParagraph,
  hiddenAnnotation,
  Cell,
  createImage,
  getRemoteImage,
  multilineText,
  getTable,
  h1,
  h1Subtitle,
  h2,
  h2Line,
  h2Alt,
  h2AltLine,
  h3,
  getImage,
  labeledText,
  markdownText,
  labeledH3Paragraphs,
  h3Alt,
  ReferencesSectionTitle,
  ReferenceTitle,
  AlternateSmallHeadingNoSpacing,
  get5050TableNoSpacing,
  pictureSource,
} from "helpers/word/newComponents";

import {
  EMPLOYEE_WORD_QUERY,
  SINGLE_REFERENCE_WORD_QUERY,
} from "queries/newWord";

import {
  sortItemsByYear,
  sortItemsByInheritedYear,
} from "helpers/getSortedItems";

// @ts-ignore
import tbfLogo from "assets/images/tbf-logo.png";

// import { readFileSync } from "fs";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { downloadWord } from "helpers/word/documents";
import { CvEntry, Employee, Maybe, NewReference } from "generated/graphql";
import { shortenSiaOrHoai } from "components/newReferences/utils";
import {
  isCompanyReference,
  referenceFormDefinition,
} from "components/editVersioned/editReference";
import { formatProjectId } from "pages/newProject/header";
// @ts-ignore
import wordStyles from "assets/word/styles_with_credits.xml?raw";
import { selectReferenceData } from "helpers/getReferenceData";

const numberingConfig: INumberingOptions = {
  config: [
    {
      reference: "tbf-numbering",
      levels: [
        {
          level: 0,
          format: LevelFormat.LOWER_LETTER,
          text: "— ",
          alignment: docx.AlignmentType.START,
          style: {
            paragraph: {
              indent: {
                left: 350,
                hanging: 350,
              },
            },
          },
        },
      ],
    },
  ],
};

// HELPER FUNCTIONS

// Helper dates
const formatDateRange = (
  since: string | null,
  from: string | null,
  to: string | null,
) => {
  from = from ? from.slice(0, 4) : "";
  to = to ? to.slice(0, 4) : "";
  if (from && to && from === to) {
    return from;
  }
  if (from && to) {
    return `${from}–${to}`;
  }
  if (from && !to) {
    return `${since} ${from}`;
  }
  return "";
};

type DocxChildren = Array<Paragraph | Table | TableOfContents>;

const applyLanguage =
  (tOrig: any, language: string) =>
  (key: string, options?: any): string =>
    tOrig(key, { lng: language, ...(options || {}) });

export const sortReferencesChronologically = (
  references: NewReference[],
): NewReference[] => {
  const referencesWithInheritedTimings = references.map((reference) => ({
    ...reference,
    inheritedFrom: selectReferenceData(reference, "from"),
    inheritedTo: selectReferenceData(reference, "to"),
  }));

  const sortedReferences = sortItemsByInheritedYear(
    referencesWithInheritedTimings,
  );
  return sortedReferences;
};

const createCvDocument = async (
  tOrig: any,
  employee: Employee,
  language: string,
  inlineCVItems?: boolean | undefined,
  referenceIdsToExport?: Set<string>,
) => {
  const t = applyLanguage(tOrig, language);

  const references = referenceIdsToExport
    ? (employee.references ?? []).filter((reference) =>
        referenceIdsToExport.has(reference.id),
      )
    : employee.references || [];

  const sortedReferences = sortReferencesChronologically(references);

  // Title, etc..
  const doc = new docx.Document({
    creator: "TBF",
    title: "",
    externalStyles: wordStyles,
    numbering: numberingConfig,
    sections: [
      {
        properties: {
          titlePage: true,
          page: {
            margin: {
              top: convertMillimetersToTwip(35),
              right: convertMillimetersToTwip(25),
              bottom: convertMillimetersToTwip(25),
              left: convertMillimetersToTwip(25),
            },
          },
        },
        headers: {
          default: await getTbfHeader(),
          first: await getTbfHeader(),
        },
        footers: {
          default: getFooter(employee),
        },
        children: [
          ...(await getCV(t, employee, language, inlineCVItems)),
          ReferencesSectionTitle(t("employee:referenceProjects")),
          ...(
            await Promise.all(
              [...sortedReferences]
                .filter((ref) => ref !== null)
                .map((ref) => getReference(t, ref!, language)),
            )
          ).reduce((acc, current) => [...acc, ...current], []),
        ],
      },
    ],
  });

  return doc;
};

export const createReferencesDocument = async (
  tOrig: any,
  references: (NewReference & { _addPageBreak?: boolean })[],
  language: string,
): Promise<docx.Document> => {
  const t = applyLanguage(tOrig, language);

  // Title, etc..
  const doc = new docx.Document({
    creator: "TBF",
    title: "",
    externalStyles: wordStyles,
    numbering: numberingConfig,
    sections: [
      {
        headers: {
          default: await getTbfHeader(),
        },
        footers: {
          default: getReferenceFooter(references, t),
        },
        children: [
          ...(
            await Promise.all(
              [...references]
                .sort(byFromDate)
                .filter((ref) => ref !== null)
                .map(async (ref) => {
                  const referenceContent = await getReference(
                    t,
                    ref!,
                    language,
                  );
                  // Add a page break if the reference has the _addPageBreak flag
                  if (ref._addPageBreak) {
                    return [...referenceContent, pageBreak()];
                  }
                  return referenceContent;
                }),
            )
          ).reduce((acc, current) => [...acc, ...current], []),
        ],
      },
    ],
  });

  return doc;
};

export const useExportCv = () => {
  const { t } = useTranslation("employee");
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);

  const language_title = {
    de: "de (ch)",
    "de-DE": "de (de)",
  };

  const exportCv = (
    employeeId: string,
    language: string,
    inlineCVItems?: boolean | undefined,
    referenceIdsToExport?: Set<string>,
  ) => {
    setLoading(true);
    apolloClient
      .query({
        ...cvQuery(employeeId),
        fetchPolicy: "network-only",
      })
      .then(async (result) => {
        const title = `CV - ${result.data.employee.firstName} ${
          result.data.employee.lastName
        }_${language_title[language] ?? language}`;
        const document = await createCvDocument(
          t,
          result.data.employee,
          language,
          inlineCVItems,
          referenceIdsToExport,
        );
        await downloadWord(title, document);
        setLoading(false);
      });
  };
  return { exportCv, loading };
};

export const useExportSingleReference = () => {
  const { t } = useTranslation("employee");
  const m = useMultiLang();
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);

  const exportSingleReference = (referenceId: string, language: string) => {
    setLoading(true);
    apolloClient
      .query({
        ...singleReferenceQuery(referenceId),
        fetchPolicy: "network-only",
      })
      .then(async (result) => {
        const title =
          referenceFormDefinition.titleString(result.data.reference, t, m) ||
          "Reference export";
        const document = await createReferencesDocument(
          t,
          [result.data.reference],
          language,
        );
        await downloadWord(title, document);
        setLoading(false);
      });
  };
  return { exportSingleReference, loading };
};

const getTbfHeader = async () => {
  const logo = await getRemoteImage(tbfLogo);

  return new docx.Header({
    children: logo
      ? [
          new Paragraph({
            children: [
              new ImageRun({
                data: logo.data,
                type: "png",
                transformation: {
                  height: logo.height / 3.5,
                  width: logo.width / 3.5,
                },
              }),
            ],
          }),
          new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
        ]
      : [],
  });
};

const getFooter = (employee) => {
  return new docx.Footer({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            size: 16,
            text: `CV ${employee.firstName} ${employee.lastName}`,
          }),
          new TextRun({
            size: 16,
            children: ["\t", PageNumber.CURRENT, `/`, PageNumber.TOTAL_PAGES],
          }),
        ],
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: TabStopPosition.MAX,
          },
        ],
      }),
    ],
  });
};

const getReferenceFooter = (references, t) => {
  const reference = references[0];
  const text = isCompanyReference(reference)
    ? t("common:project_reference")
    : `${t("reference:referenceFor")} ${reference.employee?.fullName}`;
  return new docx.Footer({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            size: 16,
            text: text,
          }),
          new TextRun({
            size: 16,
            children: ["\t", PageNumber.CURRENT, `/`, PageNumber.TOTAL_PAGES],
          }),
        ],
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: TabStopPosition.MAX,
          },
        ],
      }),
    ],
  });
};

// --
// Document sections

// The CV table is rendered either
// a. inline in the right content of the CV table or
// b. on a separate page
// depending on the content of the "trainings" field.
// When rendered inline, we pass `inline: true` here
// to render the cells slightly different.
const getCVCells = (
  t: any,
  cvItems: Maybe<CvEntry>[] | null,
  lang: string,
  inline: boolean,
): Cell[][] => {
  const m = getTextFromMultiLang(lang);
  return (cvItems ? [...cvItems] : []).map((item) => {
    const fromTo = formatDateRange(
      t("employee:cvItem.since"),
      item?.from ?? null,
      item?.to ?? null,
    );

    return [
      {
        content: [textParagraph(fromTo)],
        shading: !inline ? { fill: "f2f2f2" } : {},
        width: (100 / 16) * (inline ? 3 : 5),
        margins: inline ? { left: 0 } : undefined,
      },
      {
        content: [
          textParagraph(m(item?.institution)),
          multilineText(m(item?.description)),
        ],
        width: (100 / 16) * (inline ? 13 : 11),
        margins: {
          left: inline ? 250 : 400,
        },
      },
    ];
  });
};

const getCV = async (
  t: any,
  employee: Employee,
  lang: string,
  inlineCVItems?: boolean | undefined,
): Promise<DocxChildren> => {
  const m = getTextFromMultiLang(lang);
  const name = `${employee.firstName} ${employee.lastName}`;
  const languageLevels = (employee.languages || []).map((language) => {
    const level =
      language.level === "Native"
        ? `${m(language.language.name)} (${t("common:languageLevel:Native")})`
        : `${m(language.language.name)} (${language.level})`;
    return level;
  });

  const listParagraphOptions: IParagraphOptions = {
    numbering: { reference: "tbf-numbering", level: 0 },
    style: "ListParagraph",
  };

  const languages: Paragraph[] = (languageLevels || []).map((language) => {
    return textParagraph(language, {}, listParagraphOptions);
  });

  const diplomas: Paragraph[] = (employee.diplomas || []).map((diploma) => {
    const diplomaWithYear =
      m(diploma.diploma?.name) + (diploma.year ? ` (${diploma.year})` : "");
    return textParagraph(diplomaWithYear, {}, listParagraphOptions);
  });

  const skills: Paragraph[] = (employee.skills || []).map((skill) => {
    return textParagraph(m(skill.skill?.name), {}, listParagraphOptions);
  });

  const competences: Paragraph[] = (employee.competences || []).map(
    (competence) => {
      return textParagraph(
        m(competence.competence?.name),
        {},
        listParagraphOptions,
      );
    },
  );

  const pictureUrl = employee.colorProfilePicture ?? employee.profilePicture;
  const profilePic = pictureUrl ? await createImage(pictureUrl, 177) : null;
  const profileParagraph = new docx.Paragraph({});
  if (profilePic) {
    profileParagraph.addChildElement(profilePic);
  }

  const roleText = (employee.roles || [])
    .map((x: any) => m(x.role?.name))
    .join(", ");

  const yearOfBirth = [
    h2Alt(t("employee:yearOfBirth")),
    new Paragraph(employee.birthday ? employee.birthday.substring(0, 4) : ""),
  ];

  // CV Items
  if (inlineCVItems == undefined) {
    inlineCVItems = m(employee.trainings).length < 100;
  }

  const sortedCvItems = sortItemsByYear(employee.cv?.slice() ?? []);
  const cvCells = getCVCells(t, sortedCvItems, lang, inlineCVItems);
  const cvItems =
    cvCells.length > 0
      ? [h3(t("employee:cvItemsTitle")), getTable(cvCells)]
      : [];

  const contentLeft = [
    ...(profilePic ? [profileParagraph] : []),
    h2AltLine(),
    ...yearOfBirth,
    h2AltLine(),
    h2Alt(t("employee:skillsTitle")),
    h3Alt(t("employee:skillsShortTitle")),
    ...skills,
    h3Alt(t("employee:competencesShortTitle")),
    ...competences,
    h3Alt(t("employee:cvItem.languages")),
    ...languages,
  ];

  const contentRight = [
    h2Line(),
    h2(t("employee:aboutMe")),
    ...(employee.aboutMe ? markdownText(m(employee.aboutMe)) : []),

    h2Line(),
    h2(t("employee:career")),
    ...labeledH3Paragraphs(t("employee:cvItem.education"), diplomas),
    ...(m(employee?.trainings) !== ""
      ? labeledH3Paragraphs(
          t("employee:cvItem.furtherEducation"),
          markdownText(m(employee.trainings)),
        )
      : []),
    ...(m(employee?.additionalField?.content) !== ""
      ? labeledH3Paragraphs(
          m(employee?.additionalField?.title)
            ? m(employee?.additionalField?.title)
            : t("form:fieldName.employee.additionalField"),
          markdownText(m(employee?.additionalField?.content)),
        )
      : []),
    ...(inlineCVItems ? cvItems : []),
  ];

  return [
    h1(name, "CVTitle"),
    h1Subtitle(roleText, "CVSubtitle"),
    getTwoColumnLayout(contentLeft, contentRight, true),
    pageBreak(),
    ...(!inlineCVItems ? cvItems : []),
  ];
};

const byFromDate = (
  a: { from: string | null; to: string | null },
  b: { from: string | null; to: string | null },
) => {
  const aYear = a?.to ? new Date(a.to) : a?.from ? new Date(a.from) : 0;
  const bYear = b?.to ? new Date(b.to) : b?.from ? new Date(b.from) : 0;

  return aYear < bYear ? 1 : -1;
};

const getReferenceLayout = async (
  title: string,
  pictureCredit: Maybe<string> | undefined,
  contentLeft: Paragraph[],
  contentRight: Array<Paragraph | Table>,
  image?: string,
): Promise<DocxChildren> => {
  const pictureRun = image ? await getImage(image) : null;
  const creditSection = pictureCredit
    ? pictureSource(pictureCredit)
    : new Paragraph({ spacing: { after: 200, line: 0 } });
  return [
    ReferenceTitle(title ?? "(no title)"),
    ...(pictureRun
      ? [
          new Paragraph({
            children: [pictureRun],
          }),
          creditSection,
        ]
      : []),
    getTwoColumnLayout(contentLeft, contentRight),
    new Paragraph({}),
  ];
};

const getTwoColumnLayout = (
  contentLeft: Paragraph[],
  contentRight: Array<Paragraph | Table>,
  alignRightTitleHack?: boolean,
): Table => {
  return getTable([
    ...(alignRightTitleHack
      ? [
          [
            // With this hack we try to align the title on the right with the cell below
            {
              content: [new Paragraph({ spacing: { after: 0 } })],
              width: (100 / 16) * 5,
            },
            {
              content: contentRight,
              margins: {
                left: 400,
              },
              width: (100 / 16) * 11,
            },
          ],
        ]
      : []),
    [
      {
        content: contentLeft,
        shading: { fill: "f2f2f2" },
        width: (100 / 16) * 5,
      },
      alignRightTitleHack
        ? { mergeWithUpper: true }
        : {
            content: contentRight,
            margins: {
              left: 400,
            },
            width: (100 / 16) * 11,
          },
    ],
  ]);
};

const getEmployeeReferenceContent = (
  t: any,
  language: string,
  employeeReference: NewReference | null,
) => {
  const m = getTextFromMultiLang(language);

  if (!employeeReference) return [];

  const employeeReferencefromTo = formatDateRange(
    t("employee:cvItem.since"),
    employeeReference?.from,
    employeeReference?.to,
  );

  // Table content
  const fromTo = employeeReferencefromTo
    ? [
        {
          content: labeledText(
            t(`wordExport:handlingPeriod`),
            employeeReferencefromTo,
          ),
        },
      ]
    : [];

  const hasSiaorHoaisPhases =
    (employeeReference?.siaPhases?.length ?? 0) > 0 ||
    (employeeReference?.hoaiPhases?.length ?? 0) > 0;

  const siaPhases = hasSiaorHoaisPhases
    ? [
        {
          content: labeledText(
            t("project:phases"),
            shortenSiaOrHoai(
              employeeReference?.primaryPhase === "sia",
              employeeReference?.siaPhases ?? [],
              employeeReference?.hoaiPhases ?? [],
            ),
          ),
        },
      ]
    : [];

  const applicationAreas = employeeReference?.applicationAreas
    ? [{ content: getApplicationAreas(t, employeeReference?.applicationAreas) }]
    : [];

  const services = employeeReference?.services
    ? [{ content: getServices(t, employeeReference?.services) }]
    : [];

  const emptyCell = { content: [new Paragraph({})] };

  const tableContent = [
    ...fromTo,
    ...siaPhases,
    ...applicationAreas,
    ...services,
  ];

  const tableFirstRow =
    tableContent.length > 0
      ? [get5050TableNoSpacing([tableContent[0], tableContent[1] ?? emptyCell])]
      : [];

  const tableSecondRow =
    tableContent.length > 2
      ? [get5050TableNoSpacing([tableContent[2], tableContent[3] ?? emptyCell])]
      : [];

  return [
    ...labeledH3Paragraphs(
      t(`wordExport:myRole`) +
        ": " +
        (employeeReference.roles
          ?.map((r) => m(r.referenceRole?.name, ""))
          .join(", ") ?? t("abacusProjects:role_0")),
      markdownText(m(employeeReference?.description)),
    ),
    ...tableFirstRow,
    ...tableSecondRow,
  ];
};

const getMainProjectReferenceContent = (
  t: any,
  language: string,
  mainProjectReference: NewReference | null,
) => {
  const m = getTextFromMultiLang(language);
  const siaPhases = mainProjectReference?.siaPhases;
  const hoaiPhases = mainProjectReference?.hoaiPhases;

  return mainProjectReference
    ? [
        ...labeledH3Paragraphs(
          t(`wordExport:mainProjectTitle`),
          markdownText(m(mainProjectReference?.description)),
        ),
        get5050TableNoSpacing([
          ...(mainProjectReference?.from || mainProjectReference?.to
            ? [
                {
                  content: [
                    AlternateSmallHeadingNoSpacing(
                      t(`wordExport:projectPeriod`),
                    ),
                    new Paragraph(
                      formatDateRange(
                        t("employee:cvItem.since"),
                        mainProjectReference?.from,
                        mainProjectReference?.to,
                      ),
                    ),
                  ],
                },
              ]
            : []),
          ...((siaPhases && siaPhases.length > 0) ||
          (hoaiPhases && hoaiPhases.length > 0)
            ? [
                {
                  content: [
                    AlternateSmallHeadingNoSpacing(t("project:phases")),
                    multilineText(
                      shortenSiaOrHoai(
                        mainProjectReference?.primaryPhase === "sia",
                        siaPhases ?? [],
                        hoaiPhases ?? [],
                      ),
                    ),
                  ],
                },
              ]
            : []),
          ...(mainProjectReference?.project?.investment
            ? [
                {
                  content: [
                    AlternateSmallHeadingNoSpacing(t("wordExport:investment")),
                    getInvestment(
                      language,
                      mainProjectReference?.project?.investment || 0,
                      mainProjectReference?.project?.investmentCurrency ||
                        "CHF",
                    ),
                  ],
                },
              ]
            : []),
          ...(mainProjectReference?.applicationAreas
            ? [
                {
                  content: [
                    AlternateSmallHeadingNoSpacing(
                      t("abacusProjects:application_areas"),
                    ),
                    new Paragraph(
                      mainProjectReference.applicationAreas
                        .map((key) => t(`abacusProjects:${key}`))
                        .join(", "),
                    ),
                  ],
                },
              ]
            : []),
          ...(mainProjectReference?.services
            ? [
                {
                  content: [
                    AlternateSmallHeadingNoSpacing(
                      t("abacusProjects:services"),
                    ),
                    new Paragraph(
                      mainProjectReference.services
                        .map((key) => t(`abacusProjects:${key}`))
                        .join(", "),
                    ),
                  ],
                },
              ]
            : []),
        ]),
      ]
    : [];
};

const getFirstNonEmpty = (options: Array<string | undefined | null>) => {
  return options.find(
    (option) => option !== "" && option !== null && option !== undefined,
  );
};

const getFirstNonEmptyByField = <T,>(options: Array<T>, field: string) => {
  return options.find(
    (option) =>
      option?.[field] !== "" &&
      option?.[field] !== null &&
      option?.[field] !== undefined,
  );
};

const getReference = async (
  t: any,
  reference: NewReference,
  language: string,
): Promise<DocxChildren> => {
  const m = getTextFromMultiLang(language);
  const isEmployeeReference = !!reference.employeeId;
  const isPrivateReference = !reference.projectId;

  const employeeReference = isEmployeeReference ? reference : null;

  const projectReference = isEmployeeReference
    ? (reference.parentReference as NewReference | undefined)
    : reference;
  const mainProjectReference = projectReference?.parentReference ?? null;

  const title = isPrivateReference
    ? getFirstNonEmpty([
        m(reference?.privateReferenceProjectName),
        t("searchHighlights:privateReference"),
      ])
    : getFirstNonEmpty([
        m(reference?.title),
        m(reference?.parentReference?.title),
        m(reference?.parentReference?.parentReference?.title),
        // m(reference?.project?.name),
        t("project:noTitle"),
      ]);

  const project = reference?.project;
  const clientHidden = project?.clientHidden ?? false;
  const client =
    project?.client?.name || reference?.privateReferenceProjectClientName;

  const countryShort =
    project?.country ||
    project?.client?.country ||
    reference?.privateReferenceProjectCountry;
  const country = countryShort ? t(`abacusProjects:${countryShort}`) : null;
  const clientName = !clientHidden
    ? client
    : countryShort
      ? t(`abacusProjects:from_country_${countryShort}`)
      : null;

  const projectReferenceFromTo = formatDateRange(
    t("employee:cvItem.since"),
    projectReference?.from ?? null,
    projectReference?.to ?? null,
  );

  const privateReferenceProjectFromTo =
    reference?.privateReferenceProjectFrom ||
    reference?.privateReferenceProjectTo
      ? formatDateRange(
          t("employee:cvItem.since"),
          reference?.privateReferenceProjectFrom,
          reference?.privateReferenceProjectTo,
        )
      : null;

  const description = getFirstNonEmpty([
    m(projectReference?.description),
    // m(mainProjectReference?.description),
    // m(project?.description),
    m(reference?.privateReferenceProjectDescription),
  ]);

  const picture = getFirstNonEmptyByField(
    [
      projectReference,
      projectReference?.parentReference,
      projectReference?.parentReference?.parentReference,
      isPrivateReference ? reference : null,
    ].map((p) => ({
      url: (p?.picture as any)?.urlWord as string,
      credit: p?.pictureCredit,
    })),
    "url",
  );

  const projectInvestment =
    project?.investment || reference.privateReferenceProjectInvestment;
  const projectInvestmentCurrency =
    project?.investmentCurrency ||
    reference.privateReferenceProjectInvestmentCurrency;

  return [
    ...(await getReferenceLayout(
      title || "",
      picture?.credit,
      [
        ...(clientName
          ? [
              AlternateSmallHeadingNoSpacing(t("employee:cvItem.client")),
              new Paragraph(clientName),
              ...(country && !clientHidden ? [new Paragraph(country)] : []),
            ]
          : []),
        ...(projectReferenceFromTo
          ? labeledText(t("wordExport:projectPeriod"), projectReferenceFromTo)
          : []),
        ...(privateReferenceProjectFromTo
          ? labeledText(
              t("wordExport:projectPeriod"),
              privateReferenceProjectFromTo,
            )
          : []),
        ...(projectReference?.siaPhases ||
        projectReference?.hoaiPhases ||
        reference.privateReferenceProjectSiaPhases ||
        reference.privateReferenceProjectHoaiPhases
          ? getLabeledSiaPhases(
              t,
              projectReference?.siaPhases ??
                reference.privateReferenceProjectSiaPhases,
              projectReference?.hoaiPhases ??
                reference.privateReferenceProjectHoaiPhases,
              projectReference?.primaryPhase ??
                reference.privateReferenceProjectPrimaryPhase,
            )
          : []),
        ...(projectReference?.services ||
        reference.privateReferenceProjectServices
          ? getServices(
              t,
              projectReference?.services ??
                reference.privateReferenceProjectServices ??
                [],
            )
          : []),
        ...(projectReference?.applicationAreas ||
        reference.privateReferenceProjectApplicationAreas
          ? getApplicationAreas(
              t,
              projectReference?.applicationAreas ??
                reference.privateReferenceProjectApplicationAreas ??
                [],
            )
          : []),
        ...(!mainProjectReference && projectInvestment
          ? [
              h3Alt(t("project:investment")),
              getInvestment(
                language,
                projectInvestment,
                projectInvestmentCurrency || "CHF",
              ),
            ]
          : []),
        ...(
          projectReference?.facts ??
          reference.privateReferenceProjectFacts ??
          []
        )
          .map((f) => {
            return f ? labeledText(m(f?.key?.name), m(f?.value) || "") : [];
          })
          .reduce((prev, curr) => [...prev, ...curr], []),
        new Paragraph({}),
      ],
      [
        ...(description ? markdownText(description) : []),
        ...getEmployeeReferenceContent(t, language, employeeReference),
        ...getMainProjectReferenceContent(t, language, mainProjectReference),
        hiddenAnnotation(
          project?.abacusProjectId
            ? `Proj Nr: ${formatProjectId(project?.abacusProjectId)}`
            : "",
        ),
      ],
      picture?.url ?? undefined,
    )),
  ];
};

const getServices = (t: any, services: Array<string | null>) => {
  return services && services.length > 0
    ? labeledText(
        t("abacusProjects:services"),
        services.map((key) => t(`abacusProjects:${key}`)).join(", "),
      )
    : [];
};

const getInvestment = (
  language: string,
  investment: number,
  investmentCurrency: string,
) => {
  try {
    const lang = language === "de" ? "de-CH" : language;
    const formatter = new Intl.NumberFormat(lang ?? "de-CH", {
      minimumFractionDigits: Number.isInteger(investment) ? 0 : 1,
      style: "currency",
      currency: investmentCurrency ? investmentCurrency : "CHF",
      notation: "compact",
      compactDisplay: "long",
    } as any);

    return textParagraph(formatter.format(investment));
  } catch (_e) {
    return textParagraph(`${investment} ${investmentCurrency}`);
  }
};

const getApplicationAreas = (
  t: any,
  applicationAreas: Array<string | null>,
) => {
  return applicationAreas && applicationAreas.length > 0
    ? labeledText(
        t("abacusProjects:application_areas"),
        applicationAreas.map((key) => t(`abacusProjects:${key}`)).join(", "),
      )
    : [];
};

const getLabeledSiaPhases = (
  t: any,
  siaPhases?: Maybe<Array<string>>,
  hoaiPhases?: Maybe<Array<string>>,
  primaryPhase?: Maybe<string>,
) => {
  if (!siaPhases?.length && !hoaiPhases?.length) return [];

  // Use the primary phase to determine which phase system to use
  const useSia = primaryPhase === "sia";

  return labeledText(
    t("project:phases"),
    shortenSiaOrHoai(useSia, siaPhases ?? [], hoaiPhases ?? []),
  );
};

// --
// Queries

const cvQuery = (employeeId: string) => {
  return { query: EMPLOYEE_WORD_QUERY, variables: { id: employeeId } };
};

const singleReferenceQuery = (referenceId: string) => {
  return { query: SINGLE_REFERENCE_WORD_QUERY, variables: { id: referenceId } };
};
