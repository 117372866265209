import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { GenericFieldProps } from "./fieldProps";
import { MultiLang } from "helpers/ui/multiLang";

export type TextFieldProps = GenericFieldProps<string> & {
  multiLangValue?: MultiLang;
  placeholder?: string;
  placeholderKey?: string;
  placeholderFn?: (value: MultiLang) => string;
  lines?: number;
};

export const TextField = ({
  value,
  multiLangValue,
  onChange,
  disabled = false,
  lines = 1,
  placeholder,
  placeholderKey,
  placeholderFn,
}: TextFieldProps): JSX.Element => {
  const { t } = useTranslation();

  const displayedPlaceholder = placeholderKey
    ? t(placeholderKey)
    : placeholderFn
      ? placeholderFn(multiLangValue)
      : (placeholder ?? "");

  return lines === 1 ? (
    <input
      type="text"
      disabled={disabled}
      placeholder={displayedPlaceholder}
      className="block w-full p-2 border rounded-md shadow-sm outline-none border-grey-300 focus:ring-2 sm:text-sm focus:border-grey-900 focus:ring-grey-900"
      value={value ?? ""}
      onChange={(e) => onChange && onChange(e.currentTarget.value)}
    />
  ) : (
    <textarea
      className="block w-full p-2 border rounded-md shadow-sm outline-none border-grey-300 focus:ring-2 sm:text-sm focus:border-grey-900 focus:ring-grey-900"
      rows={lines}
      disabled={disabled}
      placeholder={displayedPlaceholder}
      value={value ?? ""}
      onChange={(e) => onChange && onChange(e.currentTarget.value)}
    ></textarea>
  );
};

export const textField = {
  renderer: TextField,
};
