import React, { useState, ReactNode, useCallback, useEffect, JSX } from "react";
import { useTranslation } from "react-i18next";
import { FullHeightTwoColumnLayout } from "layout/twoColumnLayout";
import { FaPlus, FaSpinner } from "react-icons/fa";
import { useSearch } from "pages/search/helpers";
import useInfiniteScroll from "react-infinite-scroll-hook";

import {
  ISpecializedEditFormProps,
  VersionedEntity,
} from "components/editVersioned/genericForm";
import { Popup } from "components/popup";
import { IconButton } from "components/button";
import { Search } from "components/search";

const DraftIndicator = <T extends VersionedEntity>({
  entity,
}: {
  entity: T;
}) => {
  const indicationBg = entity.draft
    ? entity.draft.isReadyForReview
      ? "bg-yellow-300"
      : "bg-red-500"
    : "bg-green-400";
  return (
    <Popup
      content={
        entity.draft
          ? entity.draft.isReadyForReview
            ? "Entwurf"
            : "In Bearbeitung"
          : "Abgenommen"
      }
    >
      <div className={`w-2 h-2 rounded-full ${indicationBg}`}></div>
    </Popup>
  );
};

export const ManageGeneric = <T extends VersionedEntity>({
  searchType,
  dataAccessor,

  editForm,
  canCreateEntity = false,
  listItemContent,
}: {
  title: ReactNode;
  searchType: string;
  dataAccessor: string;
  editForm: (props: ISpecializedEditFormProps) => ReactNode;
  canCreateEntity?: boolean;
  listItemContent: (entity: T, isActive: boolean) => ReactNode;
}): JSX.Element => {
  const [entityId, setEntityId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");

  const EditFormComponent = editForm;
  const { t } = useTranslation();

  const onSaved = useCallback(() => setEntityId(null), []);
  const onCreated = useCallback((id: string) => setEntityId(id), []);

  // We will later probably switch to ElasticSearch here - start by uncommenting the following lines
  const [{ results, resultsCount, isLoading, error }, search] = useSearch(
    {
      searchText: searchText,
      types: [searchType],
      filters: [{ type: "show_unlisted", args: true }],
      pageSize: 20,
    },
    "/api/simple-search",
  );

  const [pageNumber, setPageNumber] = useState(1);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: resultsCount > results.length,
    onLoadMore: () => {
      setPageNumber(pageNumber + 1);
    },
    disabled: !!error,
    rootMargin: "0px 0px 600px 0px",
  });

  useEffect(() => {
    setPageNumber(1);
    const cancelRequest = search(1);
    return cancelRequest;
  }, [searchText]);

  useEffect(() => {
    // only search from pageNumber change if not on page 1
    if (pageNumber !== 1) {
      const cancelRequest = search(pageNumber);
      return cancelRequest;
    }
  }, [pageNumber]);

  return (
    <div className="flex-grow w-full px-3 mx-auto overflow-y-auto max-w-screen-2xl">
      <FullHeightTwoColumnLayout
        leftContent={
          <div className="relative h-full max-h-screen">
            <div
              className="absolute top-0 bottom-0 left-0 right-0 overflow-auto pt-16"
              ref={rootRef}
            >
              {isLoading ? (
                <div className="p-3 text-xl">
                  <FaSpinner className="animate-spin" />
                </div>
              ) : null}
              {!isLoading && searchText && (
                <div className="pl-3">{resultsCount} Resultate</div>
              )}
              {results.map((searchResult) => (
                <div
                  key={searchResult.id}
                  style={{ minHeight: 65 }}
                  ref={sentryRef}
                  onClick={() => setEntityId(searchResult.id)}
                  className={`p-3 overflow-ellipsis overflow-hidden whitespace-nowrap cursor-pointer border-b border-gray-300 ${
                    entityId === searchResult.id
                      ? "bg-blue-500 text-white"
                      : "text-gray-900 hover:bg-gray-100"
                  }`}
                >
                  <div className="flex justify-between">
                    <div
                      style={{ maxWidth: "95%" }}
                      className="overflow-x-hidden overflow-ellipsis"
                    >
                      {listItemContent(
                        searchResult.data?.[dataAccessor] as T,
                        entityId === searchResult.id,
                      )}
                    </div>
                    <div>
                      <DraftIndicator
                        entity={searchResult.data?.[dataAccessor]}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="absolute top-2 left-0 w-full flex flex-wrap items-center justify-between px-2 pt-2 pb-6 -mx-2">
              <Search
                className="flex-grow mx-2 mb-2"
                width="w-32"
                text={searchText}
                setText={setSearchText}
              />
              <div className="flex mx-2 mb-2">
                {canCreateEntity ? (
                  <IconButton
                    Icon={FaPlus}
                    onClick={() => {
                      setEntityId("new");
                    }}
                    className="ml-2"
                  >
                    {t("add")}
                  </IconButton>
                ) : null}
              </div>
            </div>
          </div>
        }
        rightContent={
          entityId ? (
            <div className="h-full max-h-screen pt-4 pl-4">
              <EditFormComponent
                key={entityId}
                onSaved={onSaved}
                onCreated={onCreated}
                id={entityId}
                isOnAdminPage={true}
              />
            </div>
          ) : null
        }
      />
    </div>
  );
};
