import React, { useEffect, useState, JSX } from "react";
import axios from "axios";

import AuthContext from "../user";
import ApolloProvider from "./apolloProvider";
import { getApiUrl } from "helpers/apiUrl";
import { LoadingSpinner } from "components/loadingSpinner";
import { appsignal } from "initializers/appsignal";

// TYPES

export type User = {
  username: string;
  isWebsiteEditor: boolean;
  isContentAdmin: boolean;
};

type LoginState =
  | { type: "NotLoggedIn" }
  | { type: "LoggingIn" }
  | { type: "LoggedIn"; token: string; user: User }
  | { type: "LoginError" }
  | { type: "ExpiredError" }
  | { type: "ServerError" }
  | { type: "MSError" };

// HELPERS

const getCookieByName = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts?.length === 2) {
    return parts?.pop()?.split(";").shift();
  }
};

const loadTokenOrCookie = () => {
  return localStorage.getItem("token") || getCookieByName("TBF-Token");
};

const persistUserAndToken = (user: User, token: string) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
  // Put token in cookie too
  document.cookie = `TBF-Token=${token};path=/`;
};

const forgetUserAndToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  // Remove token from cookie too
  document.cookie = "TBF-Token=; Max-Age=0";
};

const responseToLoginState = (res: any): LoginState => {
  if (res?.status === 201 || res?.status === 200) {
    const token = res.data.meta.token;
    const userData = res.data.data;
    const user = {
      username: userData.username,
      isWebsiteEditor: userData.is_website_editor,
      isContentAdmin: userData.is_content_admin,
    };
    persistUserAndToken(user, token);
    return { token, type: "LoggedIn", user };
  }

  if (res?.status === 401) {
    forgetUserAndToken();
    return { type: "ExpiredError" };
  }

  if (res?.status === 500 || res?.status === 503) {
    return { type: "ServerError" };
  }

  return { type: "LoginError" };
};

const refreshAuthToken = async (token: string): Promise<LoginState> => {
  let user: User | null = null;
  try {
    const storedUserData = localStorage.getItem("user");
    const parsedUser = storedUserData ? JSON.parse(storedUserData) : null;
    user = parsedUser ? parsedUser : user;
  } catch (e) {
    // In case user stored in localstorage is corrupted
    console.error("Corrupted Stored user", e);
    forgetUserAndToken();
    return { type: "NotLoggedIn" };
  }

  if (!navigator.onLine && user) {
    return { token, type: "LoggedIn", user };
  } else {
    try {
      const headers = { Authorization: "bearer " + token };
      const refreshTokenUrl = `${getApiUrl()}/api/auth/sessions/refresh`;
      const response = await axios.post(refreshTokenUrl, null, { headers });
      return responseToLoginState(response);
    } catch (e: any) {
      console.error("refresh token error:", e);
      return responseToLoginState(e.response);
    }
  }
};

// AppSignal Helper function
const addBreadcrumb = (loginState: LoginState) => {
  appsignal.addBreadcrumb({
    category: "Auth",
    action: loginState.type,
    metadata:
      loginState.type === "LoggedIn"
        ? { username: loginState.user?.username }
        : ["LoginError", "MSError", "ExpiredError", "ServerError"].includes(
              loginState.type,
            )
          ? { error: loginState.type }
          : undefined,
  });
};

// MAIN COMPONENT

const AuthProvider = ({ LoginForm, children }: any): JSX.Element => {
  const [loginState, setLoginState] = useState<LoginState>({
    type: "NotLoggedIn",
  });

  useEffect(() => {
    addBreadcrumb(loginState);
  }, [loginState]);

  const logOut = () => {
    setLoginState({ type: "NotLoggedIn" });
    forgetUserAndToken();
  };

  useEffect(() => {
    const token = loadTokenOrCookie();
    const MSError = getCookieByName("TBF-Auth-Error");

    if (token) {
      setLoginState({ type: "LoggingIn" });
      refreshAuthToken(token).then(setLoginState);
    }

    if (MSError) {
      // Reset error
      setLoginState({ type: "MSError" });
      document.cookie = "TBF-Auth-Error=; Max-Age=0";
    }
  }, []);

  if (loginState.type === "LoggingIn") {
    return <LoadingSpinner />;
  }

  if (loginState.type === "LoggedIn") {
    return (
      <AuthContext.Provider
        value={{
          logOut,
          user: loginState.user,
          token: loginState.token,
        }}
      >
        <ApolloProvider
          username={loginState.user?.username}
          token={loginState.token}
        >
          {children}
        </ApolloProvider>
      </AuthContext.Provider>
    );
  }

  return <LoginForm loginState={loginState.type} />;
};

export default AuthProvider;
