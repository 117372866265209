import React, { useEffect, useState, JSX } from "react";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";

import { EditOnepagerModal } from "components/editVersioned/editOnepager";
import { ListItem } from "./listItem";

import { ALL_ONEPAGERS_QUERY } from "queries/onepager";
import { useInfiniteScrollWithGraphql } from "helpers/useInfiniteScrollWithGraphql";

import { IconButton } from "components/button";
import { FaPlus } from "react-icons/fa";
import { LoadingSpinner } from "components/loadingSpinner";
import { useSearch } from "pages/search/helpers";
import useInfiniteScroll from "react-infinite-scroll-hook";

type ListProps = {
  onepagerType: string;
  archived?: boolean;
};
type ListType = (props: ListProps) => JSX.Element;

export const List: ListType = ({ onepagerType, archived = false }) => {
  const { t } = useTranslation(["onepager", "common"]);
  const [_location, navigate] = useLocation();
  const [showEditOnepager, setShowEditOnepager] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchResults, search] = useSearch({
    searchText,
    types: ["onepager"],
    filters: [{ type: "archived", args: archived }],
    useFuzzy: true,
    enrichResultsMethod: "onepager",
  });

  const { data, isLoading, refetchAll } = useInfiniteScrollWithGraphql({
    gqlQuery: ALL_ONEPAGERS_QUERY,
    queryDataAccessor: "allOnepagers",
    pageSize: 20,
    extraVariables: { onepagerType },
  });

  const [sentryRef] = useInfiniteScroll({
    loading: searchResults.isLoading,
    hasNextPage: searchResults.resultsCount > searchResults.results.length,
    onLoadMore: () => {
      setPageNumber(pageNumber + 1);
    },
    disabled: !!searchResults.error,
    rootMargin: "0px 0px 600px 0px",
  });

  useEffect(() => {
    setPageNumber(1);
    const cancelRequest = search(1);
    return cancelRequest;
  }, [searchText]);

  useEffect(() => {
    // only search from pageNumber change if not on page 1
    if (pageNumber !== 1) {
      const cancelRequest = search(pageNumber);
      return cancelRequest;
    }
  }, [pageNumber]);

  const displayedOnepager =
    searchText.length > 0
      ? searchResults?.results?.map((result) => result.data.onepager)
      : data?.allOnepagers;

  return (
    <div className="mt-8">
      <div className="flex items-center justify-between">
        <div>
          {onepagerType !== "my" ? (
            <input
              type="text"
              className="block px-2 py-1 text-blue-500 placeholder-blue-500 border border-gray-300 rounded shadow-inner outline-none w-96 md:mt-0"
              placeholder={t(`common:search`) + "..."}
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          ) : null}
        </div>
        <div className="flex items-center justify-end">
          {!archived ? (
            <IconButton
              Icon={FaPlus}
              onClick={() => {
                setShowEditOnepager(true);
              }}
            >
              {t("onepager:addOnepager")}
            </IconButton>
          ) : null}
        </div>
      </div>
      <div className="mt-8 mb-8 space-y-2">
        {displayedOnepager?.map((onepager) => {
          return (
            <div className="relative z-0" key={onepager.id} ref={sentryRef}>
              <ListItem
                key={onepager.id}
                archived={archived}
                onepager={onepager}
                setIsArchiving={setIsArchiving}
                onDuplicate={(id) => {
                  navigate(`/onepagers/edit/${id}?openSettings=true`);
                }}
                setSelectedId={setSelectedId}
              />
              {onepager.id == selectedId && isArchiving && (
                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          );
        })}
        {(isLoading || searchResults.isLoading) && <LoadingSpinner />}
      </div>

      <EditOnepagerModal
        open={showEditOnepager}
        close={() => {
          setShowEditOnepager(false);
        }}
        newEntity={{}}
        onSave={() => refetchAll()}
      />
    </div>
  );
};
