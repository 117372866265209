import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "wouter";
import * as Menubar from "@radix-ui/react-menubar";

const GridIcon = () => (
  <svg
    focusable="false"
    preserveAspectRatio="xMidYMid meet"
    fill="currentColor"
    width="16"
    height="16"
    viewBox="0 0 32 32"
    role="img"
    className="h-7 w-7 md:h-5 md:w-5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 4H18V8H14zM4 4H8V8H4zM24 4H28V8H24zM14 14H18V18H14zM4 14H8V18H4zM24 14H28V18H24zM14 24H18V28H14zM4 24H8V28H4zM24 24H28V28H24z"></path>
  </svg>
);

const SidebarLink = ({ title, href, external, itemKey }: any): JSX.Element => {
  const [location, _navigate] = useLocation();
  let isActive = location.startsWith(href);
  if (
    itemKey === "reference-data" &&
    location.startsWith("/manage-data/drafts")
  ) {
    // A hacky way to make sure that when on the draft page, we only highlight the drafts menu item
    isActive = false;
  }
  const linkClass = `flex items-center justify-between space-x-3 text-sm whitespace-nowrap text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer ${
    isActive ? "font-medium text-black" : "font-normal text-gray-800"
  }`;

  return (
    <>
      {external ? (
        <a className={linkClass} href={href}>
          <Menubar.Item className="px-4 py-4 text-lg md:py-2 md:text-sm w-full">
            {title}
          </Menubar.Item>
        </a>
      ) : (
        <Link className={linkClass} href={href}>
          <Menubar.Item className="px-4 py-4 text-lg md:py-2 md:text-sm w-full">
            {title}
          </Menubar.Item>
        </Link>
      )}
    </>
  );
};

const options = (isContentAdmin: boolean, isWebsiteEditor: boolean): any[] => {
  return [
    {
      key: "wiki",
      links: [
        {
          key: "people",
          href: "/wiki/menschen",
        },
        {
          key: "projects",
          href: "/wiki/projekte",
        },
        {
          key: "expertise",
          href: "/wiki/fachliches",
        },
      ],
    },
    {
      key: "tools",
      links: [
        {
          key: "fileShare",
          href: "/fileshare",
        },
        {
          key: "acquisitiontool",
          href: "/onepagers/all",
        },
        {
          key: "passwordmanager",
          href: "https://pw.tbf.ch/",
          external: true,
        },
        {
          key: "passwordshare",
          href: "https://snappass.tbf.ch/",
          external: true,
        },
      ],
    },
    {
      key: "it",
      links: [
        {
          key: "ticketing",
          href: "https://ticketing.tbf.ch",
          external: true,
        },
        {
          key: "systemStatus",
          href: "https://status.tbf.ch/",
          external: true,
        },
        {
          key: "wifi",
          href: "/wiki/fachliches/it/wlan",
        },
        {
          key: "exchangeAndSupport",
          href: "https://teams.microsoft.com/l/team/19%3ab96dadd830b64a988bc91e09ac218e34%40thread.skype/conversations?groupId=0fe2f43b-84ac-438d-af28-b7c44960b3e5&tenantId=a9da9e3c-a0c6-4f03-bb67-6af6ad7abac9",
          external: true,
        },
        {
          key: "employeeRegister",
          href: "https://mavz.tbf.ch",
          external: true,
        },
      ],
    },

    isContentAdmin || isWebsiteEditor
      ? {
          key: "admin",
          links: [
            isContentAdmin
              ? {
                  key: "reference-data",
                  href: "/manage-data/reference-data",
                }
              : null,
            isContentAdmin
              ? {
                  key: "contentAdmin",
                  href: "/manage-data/drafts",
                }
              : null,
            isContentAdmin
              ? {
                  key: "fileIndexAdmin",
                  href: "/admin/file-indexer",
                }
              : null,
            // Temporarily hidden
            // isContentAdmin
            //   ? {
            //       key: "exportReferences",
            //       href: "/export-references",
            //     }
            //   : null,
            isWebsiteEditor
              ? {
                  key: "website-cms",
                  href: "https://stories.tbf.ch/cms/desk",
                  external: true,
                }
              : null,
          ].filter((item) => !!item),
        }
      : null,
  ].filter((item) => !!item);
};

export const MainMenu: React.FC<{
  isContentAdmin: boolean;
  isWebsiteEditor: boolean;
}> = ({ isContentAdmin, isWebsiteEditor }) => {
  const { t } = useTranslation("navigation");

  return (
    <Menubar.Root>
      <Menubar.Menu>
        <Menubar.Trigger className="focus-visible:ring-inset button focus bg-white hover:bg-gray-10">
          <GridIcon />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content
            align="start"
            sideOffset={5}
            alignOffset={-3}
            className="z-40 -mt-1 min-w-[8rem] overflow-hidden border bg-white px-4 py-4 md:px-0 h-[calc(100vh-60px)] w-screen overflow-y-auto py-3 pb-16 md:w-64"
          >
            {options(isContentAdmin, isWebsiteEditor).map(({ key, links }) => {
              return (
                <Menubar.Group key={key}>
                  <Menubar.Item disabled className="my-4">
                    <Menubar.Label className="text-gray-500 mx-4 text-normal md:text-xs">
                      {t(key)}
                    </Menubar.Label>
                    <Menubar.Separator className="bg-gray-10 mx-4 h-px" />
                  </Menubar.Item>
                  {links.map((link) => {
                    return (
                      <SidebarLink
                        key={link.key}
                        itemKey={link.key}
                        title={t(link.key as any)}
                        href={link.href}
                        external={link?.external}
                      />
                    );
                  })}
                </Menubar.Group>
              );
            })}
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
};
