import React, { useState, JSX } from "react";
import { IoMdClose } from "react-icons/io";
import { FaSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { Modal } from "components/modal";
import { IconButton } from "components/button";
import { Toggle } from "components/toggle";
import isFeatureEnabled from "helpers/isFeatureEnabled";

const FEATURE_FLAGS = ["abacus-project-details"];

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

export const FeatureFlagModal = ({
  isOpen,
  setIsOpen,
}: ModalProps): JSX.Element => {
  const { t } = useTranslation(["common", "features"]);

  const initialState = FEATURE_FLAGS.reduce((state, flag) => {
    state[flag] = isFeatureEnabled(flag);
    return state;
  }, {});

  const [enabled, setEnabled] = useState(initialState);

  const activateFeatureFlag = (flag) => {
    const newEnabled = { ...enabled, [flag]: !enabled[flag] };
    setEnabled(newEnabled);
  };

  const applyFeatureFlags = () => {
    if (Object.values(enabled).every((f) => !f)) {
      window.localStorage.removeItem("features");
    } else {
      const ffEnabled = Object.entries(enabled)
        .filter(([_f, v]) => !!v)
        .map(([f, _v]) => f)
        .join(",");

      window.localStorage.setItem("features", ffEnabled);
    }
    window.location.reload();
  };

  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("features:title")}
        </div>
      }
      open={isOpen}
      close={() => setIsOpen(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t("common:close")}
          </IconButton>
          <IconButton Icon={FaSave} onClick={applyFeatureFlags}>
            {t("features:apply")}
          </IconButton>
        </div>
      }
    >
      <div className="px-8 my-8">
        <p className="mb-12">{t("features:description")}</p>
        {FEATURE_FLAGS.map((flag) => (
          <div
            key={flag}
            className="flex my-4 justify-between items-top gap-x-8"
          >
            <div>
              <div className="text-lg">{t(`features:${flag}` as any)}</div>
              <div className="text-sm text-gray-500">
                {t(`features:${flag}_description` as any)}
              </div>
            </div>
            <Toggle
              enabled={enabled[flag]}
              setEnabled={() => activateFeatureFlag(flag)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};
