import React, { useCallback, useState, JSX } from "react";
import { GenericField, GenericFieldProps } from "./form/fieldProps";
import { useTranslation } from "react-i18next";
import { extractMulti } from "helpers/ui/multiLang";
import { FaLanguage, FaLink, FaUnlink } from "react-icons/fa";
import { ImArrowUpRight2 } from "react-icons/im";
import { Popup } from "./popup";
import { languages } from "helpers/languages";
import { toMultiLangField, VersionedEntity } from "./editVersioned/genericForm";
import { ExternalConnection } from "generated/graphql";
import { groupBy, pick } from "ramda";
import { SingleIconButton } from "components/button";
import { ApolloClient } from "@apollo/client";
import { hiddenField } from "./form/hiddenField";
import { NoUrlTabBar } from "components/tab";

export const virtualField = {
  name: "",
  displayIf: () => false,
  component: hiddenField,
};

export type FieldDefinition<T extends GenericFieldProps<any>> = {
  name: string;
  descriptionKey?: (entity: T) => string;
  component: GenericField<T>;
  required?: boolean;
  controlledFields?: string[];
  dependentData?: string;
  hidableFromExport?: boolean;
  props?: T;
  onlyVisibleToAdmins?: boolean;
  displayIf?: (entity: T) => boolean;
  multiLang?: boolean;
  multiLangIcon?: "admin" | "custom";
  usedIn?: string;
  skipFieldOnSave?: boolean;
  useFullWidth?: boolean;
  readOnly?: boolean;
  navTab?: string;
  onSaveAction?: (
    apolloClient: ApolloClient<any>,
    entity: Partial<T>,
    value: any,
  ) => Promise<any>;
};

export type FormDefinition<T> = {
  fields: Partial<Record<keyof T, FieldDefinition<any>>>;
  title: ({
    entity,
    t,
  }: {
    entity?: Partial<T>;
    t: any;
    close?: () => void;
  }) => any;
  titleString: (
    entity?: Partial<T>,
    t?: any,
    m?: any,
  ) => string | null | undefined;
  link: (entity: Partial<T>) => string;
  previewText?: (entity: Partial<T>) => string;
  dataDependencies?: {
    fetchFunction: (apolloClinet: any, value: any) => Promise<any>;
    key: string;
    resetFieldKeysOnFetch?: string[];
  }[];
};

export type FormProps<T> = {
  formDefinition: FormDefinition<T>;
  values: Partial<T>;
  setValues: (values: Partial<T>) => void;
  currentLanguage: string;
  newEntity?: boolean;
  isOnAdminPage: boolean;
  closeModal?: () => void;
};

export const extractMultis = <T,>({
  values,
  formDefinition,
}: {
  values: Partial<T>;
  formDefinition: FormDefinition<T>;
}): Partial<T> => {
  return Object.keys(values).reduce((acc, key) => {
    const definition = formDefinition.fields[key];
    return {
      ...acc,
      [key]:
        definition && !!definition.multiLang
          ? extractMulti(values?.[key])
          : values?.[key],
    };
  }, {} as T);
};

const TranslationIndicatorManual = ({ missingLanguages }) => {
  const missingLanguagesString =
    missingLanguages.length > 0
      ? `fehlende Sprachen: ${missingLanguages.join(", ")}`
      : "alle Sprachen übersetzt";
  return (
    <Popup content={`Mehrsprachiges Feld - ${missingLanguagesString}`}>
      <FaLanguage
        className={`text-3xl ${
          missingLanguages.length > 0 ? "text-red-500" : "text-green-500"
        }`}
      />
    </Popup>
  );
};

const TranslationIndicator = ({ value, field }) => {
  const { t } = useTranslation("form");

  if (field?.multiLangIcon === "admin") {
    return (
      <Popup content={t("adminTranslated")}>
        <FaLanguage className="text-3xl text-gray-400" />
      </Popup>
    );
  } else if (field?.multiLangIcon === "custom") {
    const missingLanguages =
      field.component?.missingTranslationLanguages(value);
    return <TranslationIndicatorManual missingLanguages={missingLanguages} />;
  } else {
    const missingLanguages = languages.filter((l) => !value?.[l]);
    return <TranslationIndicatorManual missingLanguages={missingLanguages} />;
  }
};

const UsageIndicator = ({ text }) => {
  return (
    <Popup content={text}>
      <div className="mt-2 bg-gray-400 border border-2 border-white rounded">
        <ImArrowUpRight2 className="text-xs text-white bg-gray-400" />
      </div>
    </Popup>
  );
};

const AbacusConnectionIndicator = ({
  fieldKey,
  readOnly,
  connectedFields,
  onLink,
  onUnlink,
}: {
  fieldKey: string;
  readOnly: boolean;
  connectedFields: ExternalConnection[];
  className?: string;
  onUnlink: () => void;
  onLink: () => void;
}) => {
  const { t } = useTranslation("form");
  const connection = connectedFields.find((v) => v.field === fieldKey);
  const className = readOnly ? "text-gray-500" : "text-blue-500";
  if (!connection) {
    return null;
  } else if (connection.isConnected) {
    return (
      <Popup content={t("externalSourceWillDisconnect")}>
        <SingleIconButton
          disabled={readOnly}
          onClick={onUnlink}
          className="ml-2"
        >
          <FaUnlink size="1.25em" className={`m-1 ${className}`} />
        </SingleIconButton>
      </Popup>
    );
  } else {
    return (
      <Popup content={t("externalSourceWillConnect")}>
        <SingleIconButton disabled={readOnly} onClick={onLink} className="ml-2">
          <FaLink size="1.25em" className={`m-1 ${className}`} />
        </SingleIconButton>
      </Popup>
    );
  }
};

const AbacusConnectionValue = ({
  fieldKey,
  connectedFields,
  Field,
}: {
  fieldKey: string;
  Field: any;
  connectedFields: ExternalConnection[];
}) => {
  const { t } = useTranslation("form");
  const connection = connectedFields.find((v) => v.field === fieldKey);
  let previewValue: any = null;
  if (connection?.previewValue) {
    previewValue = JSON.parse(connection.previewValue);
  }
  if (connection && !connection.isConnected) {
    return (
      <div className="flex flex-row mt-4 items-center">
        <div className="text-sm text-gray-500 mr-2 ">{t("valueInAbacus")}</div>
        <div className="flex-grow">
          <Field value={previewValue} disabled={true} />
        </div>
      </div>
    );
  }
};

export const FieldName = ({ field, values, fieldKey }) => {
  const { t } = useTranslation("form");

  const descriptionKey = field.descriptionKey
    ? field.descriptionKey(values)
    : field.name;

  const isMultiLangField = !!field.multiLang;

  return (
    <div className="flex justify-between break-word">
      <div className="flex-1">
        <div className="font-medium">
          {t(`fieldName.${field.name}` as any) + (field.required ? "*" : "")}
        </div>
        <div className="text-xs text-gray-500 whitespace-pre-line">
          {t(`fieldDescription.${descriptionKey}` as any)}
        </div>
      </div>
      <div className="flex justify-end w-24 space-x-2">
        {field.usedIn && <UsageIndicator text={t(field.usedIn)} />}
        {(isMultiLangField || field.multiLangIcon) && (
          <TranslationIndicator value={values[fieldKey]} field={field} />
        )}
      </div>
    </div>
  );
};

export const Form = <T extends VersionedEntity>(
  props: FormProps<T>,
): JSX.Element => {
  const { t } = useTranslation("form");
  const { formDefinition, values } = props;

  const extractedValues = extractMultis({ values, formDefinition });

  const groupByTab = groupBy(([_key, field]: [string, FieldDefinition<T>]) => {
    if (field.navTab == null) {
      return "coreDetails";
    } else {
      return field.navTab;
    }
  });

  const groupedFields = groupByTab(Object.entries(formDefinition.fields));
  const multipleTabsPresent = Object.keys(groupedFields).length > 1;

  const panes = Object.entries(groupedFields).map(([key, _fields]) => {
    return {
      name: t(`fieldName.reference.${key}` as any),
      annotation:
        key === "internalComments" && (values as any)?.internalNote?.length > 0
          ? "*"
          : undefined,
      key: key,
    };
  });

  const [activeTab, setActiveTab] = useState("coreDetails");

  return (
    <div className="mt-4">
      <div>
        {multipleTabsPresent ? (
          <NoUrlTabBar
            panes={panes}
            onChange={setActiveTab}
            selectedTab={activeTab}
          />
        ) : null}
      </div>
      <FormFields
        fields={groupedFields[activeTab]}
        {...props}
        values={extractedValues}
      />
    </div>
  );
};

const FormFields = ({
  fields,
  currentLanguage,
  values,
  setValues,
  newEntity = false,
  isOnAdminPage,
  closeModal,
}: any) => {
  return (
    <>
      {fields.map(([key, field]) => {
        if (!field) {
          return null;
        }

        const isMultiLangField = !!field.multiLang;
        const Field = isMultiLangField
          ? useCallback(toMultiLangField(field.component.renderer), [field])
          : field.component.renderer;

        const updateValue = (v: any) => {
          if (field.controlledFields) {
            setValues({
              ...values,
              ...v,
            });
          } else {
            setValues({
              ...values,
              [key]: v,
            });
          }
        };
        const value = field.controlledFields
          ? pick(field.controlledFields, values)
          : values[key];

        const isAbacusConnected = newEntity
          ? false
          : !!values.connectedFields?.find((v) => v.field === key)?.isConnected;
        const disconnectField = () => {
          setValues({
            ...values,
            connectedFields: values.connectedFields?.map((c) => {
              if (c.field !== key) {
                return c;
              } else {
                return { ...c, isConnected: false };
              }
            }),
          });
        };
        const connectField = () => {
          setValues({
            ...values,
            connectedFields: values.connectedFields?.map((c) => {
              if (c.field !== key) {
                return c;
              } else {
                return { ...c, isConnected: true };
              }
            }),
          });
        };

        const shouldDisplay = field.displayIf ? field.displayIf(values) : true;
        const hideIfNotAdminPage = field.onlyVisibleToAdmins && !isOnAdminPage;
        const shouldHideField = hideIfNotAdminPage || !shouldDisplay;
        const readOnly = field.readOnly && !isOnAdminPage;
        const renderedField = (
          <Field
            value={value}
            onChange={updateValue}
            language={currentLanguage}
            key={`${key}-${currentLanguage}`}
            disabled={isAbacusConnected || readOnly}
            entity={values}
            closeModal={closeModal}
            {...field.props}
          />
        );

        return shouldHideField ? null : field.useFullWidth ? (
          renderedField
        ) : (
          <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3" key={key}>
            <div className="col-span-1">
              <FieldName field={field} values={values} fieldKey={key} />
            </div>
            <div className="col-span-2">
              <div className="flex flex-row items-start content-start">
                <div className="flex-grow">{renderedField}</div>
                {!newEntity && (
                  <AbacusConnectionIndicator
                    readOnly={readOnly}
                    fieldKey={key}
                    connectedFields={values.connectedFields ?? []}
                    onUnlink={disconnectField}
                    onLink={connectField}
                  />
                )}
              </div>

              <AbacusConnectionValue
                Field={(props) => (
                  <Field
                    language={currentLanguage}
                    entity={values}
                    {...field.props}
                    {...props}
                  />
                )}
                fieldKey={key}
                connectedFields={values.connectedFields ?? []}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};
