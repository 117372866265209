import React, { useContext, JSX } from "react";
import AppContext from "context/app";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { CustomCreatable } from "./creatable";
import { getTextFromMultiLang } from "helpers/multiLang";

type CompetenceType = {
  id?: string;
  competenceId: string | null;
  newText?: string;
};
type CompetencesType = CompetenceType[];

type CompetenceFieldProps = GenericFieldProps<CompetencesType>;

const CompetencesField = ({
  value,
  onChange,
  disabled,
  language,
}: CompetenceFieldProps): JSX.Element => {
  const { catalogues } = useContext(AppContext);
  const m = getTextFromMultiLang(language ?? "de");

  const allCompetences = catalogues?.competences?.map((competence: any) => ({
    label: m(competence?.name),
    value: `${competence.id}`,
  }));

  return (
    <div className="text-sm">
      <CustomCreatable
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={allCompetences}
        idValue="competenceId"
      />
    </div>
  );
};

export const competencesField: GenericField<CompetencesType> = {
  renderer: CompetencesField,
  onBeforeSave: (value) =>
    value
      ?.map((v) => ({
        competenceId: v.competenceId,
        newText: v.newText,
      }))
      .filter(
        (v) =>
          (v.competenceId ? +v.competenceId : 0) > 0 ||
          (v.newText?.length ?? 0) > 0,
      ),
  equals: (a, b) => {
    return (
      (a?.map((v) => `${v.competenceId}-${v.newText ?? ""}`).join(",") ??
        "") ===
      (b?.map((v) => `${v.competenceId}-${v.newText ?? ""}`).join(",") ?? "")
    );
  },
};
