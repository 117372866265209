import React, { JSX } from "react";
import { useQuery } from "@apollo/client";

import { NEW_PROJECT_QUERY } from "queries/newProject";

import Project from "./project";
import { useEffect } from "react";
import { useLocation } from "wouter";

const NewProjectShell = ({
  id,
  accountingId,
  archivedProjectId,
  tab,
}: {
  id?: string;
  accountingId?: string;
  archivedProjectId?: string;
  tab?: string;
}): JSX.Element => {
  const { data, loading } = useQuery(NEW_PROJECT_QUERY, {
    variables: { id, accountingId, archivedProjectId },
  });
  const [_location, navigate] = useLocation();
  const params: { tab?: string } = { tab };
  const loadedId = id ?? data?.newProject?.id;

  useEffect(() => {
    if (!id && (accountingId || archivedProjectId) && loadedId) {
      const tab = params.tab ? `/${params.tab}` : "";
      navigate(`/project/${loadedId}${tab}`, { replace: true });
    }
  }, [loadedId]);

  return (
    <Project
      id={loadedId}
      project={data?.newProject}
      loading={loading}
      currentEmployee={data?.me}
      tab={tab}
    />
  );
};

export default NewProjectShell;
