import React, { JSX } from "react";

import { FormDefinition } from "components/form";
import {
  EDIT_WIKI_PAGE_MUTATION,
  EDIT_WIKI_PAGE_QUERY,
  CREATE_WIKI_PAGE_MUTATION,
} from "queries/wiki";
import {
  EditModal,
  EditVersionedProps,
} from "components/editVersioned/genericForm";
import { WikiPage } from "generated/graphql";

import { textField } from "components/form/textField";
import { slugField } from "components/form/slugField";
import { tagsField } from "components/form/tagsField";
import { hiddenField } from "components/form/hiddenField";
import { employeesField } from "components/form/employeesField";
import {
  editSubPagesOrder,
  wikiSubPagesField,
} from "components/form/wikiSubPagesField";
import { renameSlugField } from "components/form/renameSlugField";
import { Link } from "wouter";
import { HiChevronRight } from "react-icons/hi";
import { useMultiLang } from "helpers/multiLang";
import { getFirstNonEmpty } from "helpers/languages";
// MAIN COMPONENT

const SlugLinkList = ({ entity, close }): JSX.Element => {
  const m = useMultiLang();
  return (
    <div className="flex flex-row justify-between">
      <div className="mt-1 flex flex-row space-x-1 flex-wrap items-center">
        {entity.allParentPages?.length == 0 ? (
          <div className="text-xl font-medium text-red-500">
            {m(entity.title)}
          </div>
        ) : (
          <>
            {entity.allParentPages?.map((parentPage) => {
              return (
                <React.Fragment key={parentPage?.id}>
                  <Link
                    href={`/wiki/${parentPage?.slugs?.join("/").toString()}`}
                    onClick={() => close()}
                  >
                    <div className="text-xl font-medium text-gray-350 hover:text-gray-950 transition">
                      {m(parentPage?.title)}
                    </div>
                  </Link>
                  <div className="text-xl text-gray-350">
                    <HiChevronRight />
                  </div>
                </React.Fragment>
              );
            })}
            <div className="text-xl font-medium text-red-500">
              {m(entity.title)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const wikiPageFormDefinition: FormDefinition<
  WikiPage & {
    renameSlugs: void;
  }
> = {
  title: ({ entity, t, close }) => (
    <>
      <div> {entity?.id ? t("wiki:settings") : t("wiki:create")}</div>
      {entity?.id ? <SlugLinkList entity={entity} close={close} /> : null}
    </>
  ),
  titleString: (entity) => `${entity?.id}`,
  link: (entity) => `/wiki/${entity.id}`,
  fields: {
    parentPageId: {
      name: "wiki.parentPageId",
      component: hiddenField,
      displayIf: () => false,
    },
    title: {
      name: "wiki.title",
      component: textField,
      multiLang: true,
      props: {
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    slugs: {
      name: "wiki.slugs",
      component: slugField,
      displayIf: (page) => !page.id,
    },
    tags: {
      name: "wiki.tags",
      component: tagsField,
    },
    employeeIdsToAsk: {
      name: "wiki.employeeIdsToAsk",
      component: employeesField,
      props: { isMulti: true },
    },
    renameSlugs: {
      name: "wiki.slugs",
      component: renameSlugField,
      displayIf: (e) => e?.id && e.slugs,
      skipFieldOnSave: true,
    },
    subPages: {
      name: "wiki.subPages",
      component: wikiSubPagesField,
      displayIf: (e) => e?.id && e.slugs,
      skipFieldOnSave: true,
      onSaveAction: editSubPagesOrder,
    },
  },
};

const versionedAttributes = (
  id,
  refetchQueries,
): EditVersionedProps<WikiPage> => ({
  id,
  editMutation: EDIT_WIKI_PAGE_MUTATION,
  query: EDIT_WIKI_PAGE_QUERY,
  queryDataAccessor: "wikiPage",
  editDataAccessor: "editAndApproveWikiPage",
  formDefinition: wikiPageFormDefinition,
  refetchQueries,
  createDataAccessor: "createApprovedWikiPage",
  createMutation: CREATE_WIKI_PAGE_MUTATION,
});

export const EditWikiPageModal = ({
  open,
  id,
  close,
  newEntity,
}: {
  open: boolean;
  id?: string;
  close: () => void;
  newEntity?: Partial<WikiPage>;
}) => {
  return (
    <EditModal
      open={open}
      editVersioned={versionedAttributes(id ?? "new", [
        "GetWikiPage",
        "GetCatalogs",
      ])}
      newEntity={newEntity}
      hideReviewWorkflowButtons={true}
      close={close}
    />
  );
};
