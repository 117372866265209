import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/button";
import { FaSave, FaUndo, FaEdit, FaSpinner } from "react-icons/fa";
import { LanguageSelector } from "components/languageSelector";
import { VersionList } from "pages/wiki/VersionList";
import { FullEditor } from "./tiptapEditor/components/fullEditor";
import { ImCopy } from "react-icons/im";

const tiptapPlaceholder = (title, body) => {
  return `
    <h1>${title}</h1>
    <p>${body}</p>
  `;
};

export const Editor = ({
  slugs,
  language,
  setLanguage,
  content,
  onSave,
  readOnly,
  page,
}) => {
  const { t } = useTranslation(["wiki", "common"]);
  const [location, _navigate] = useLocation();
  const editorInstance = useRef<any>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [performingSave, setPerformingSave] = useState(false);
  const currentContent = () => content?.[language];
  const [editorContent, setEditorContent] = useState(currentContent());
  const [counter, setCounter] = useState(0);

  const handleSave = () => {
    setPerformingSave(true);
    const data = editorInstance.current?.getHTML();
    onSave(data, language);

    setPerformingSave(false);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const resetTiptap = () => {
    const c = content?.[language];
    if (editorInstance.current) {
      if (c && c !== "") {
        editorInstance.current.commands.setContent(c);
      } else {
        editorInstance.current.commands.setContent(
          tiptapPlaceholder(
            t("wiki:placeholderTitle"),
            t("wiki:placeholderBodyTiptap"),
          ),
        );
      }
    }
  };

  const handleDiscard = () => {
    setEditorContent(currentContent());
    setIsEditing(false);
    resetTiptap();
  };

  useEffect(() => {
    setEditorContent(currentContent());
    resetTiptap();
  }, [content?.[language]]);

  const [showsVersionsList, setShowsVersionsList] = useState(false);

  // We use the counter to force a remount of the editor component.
  // This is because the editor component is not reactive.
  // Also, when trying to making it reactive, we encountered several bugs.
  // -> Read comment in the editor wrapper component.
  useEffect(() => {
    setCounter(counter + 1);
  }, [language, isEditing, editorContent, location]);

  return (
    <>
      <div className="flex flex-col justify-between w-full h-full">
        <div>
          <FullEditor
            page={page}
            slugs={slugs}
            onSubPageCreated={(newUrl) => {
              window.open(newUrl, "_blank")?.focus();
              handleSave();
            }}
            initialContent={
              editorContent
                ? editorContent
                : tiptapPlaceholder(
                    t("wiki:placeholderTitle"),
                    t("wiki:placeholderBodyTiptap"),
                  )
            }
            language={language}
            editorRef={editorInstance}
            readOnly={readOnly || !isEditing}
            topRightElement={
              <LanguageSelector
                currentLanguage={language}
                setCurrentLanguage={setLanguage}
              />
            }
          />
        </div>

        <div className="sticky bottom-0 z-10 w-full py-4 pl-8 ml-1 bg-white bg-opacity-100">
          {!readOnly ? (
            <div className="flex flex-row items-start justify-between">
              <div className="flex flex-row items-center">
                <div className="text-sm leading-4 text-gray-350">
                  {!isEditing ? (
                    <p>
                      {t("wiki:lastEditedBy")}{" "}
                      {page.modifiedBy?.fullName ? (
                        <Link
                          href={`/employee/${page.modifiedBy?.id}`}
                          className="underline"
                        >
                          {page.modifiedBy?.fullName}
                        </Link>
                      ) : (
                        t("wiki:unknownName")
                      )}
                    </p>
                  ) : (
                    <p>{t("wiki:editingPage", { version: page.version })}</p>
                  )}
                </div>
                <div className="flex ml-3 justify-center">
                  <IconButton
                    Icon={ImCopy}
                    onClick={() => setShowsVersionsList(true)}
                  >
                    {t("wiki:versionHistory")}
                  </IconButton>
                </div>
              </div>

              {isEditing ? (
                <div className="flex items-center space-x-2">
                  <IconButton Icon={FaUndo} onClick={handleDiscard}>
                    {t("common:cancel")}
                  </IconButton>
                  <IconButton
                    Icon={
                      performingSave
                        ? () => <FaSpinner className="animate-spin" />
                        : FaSave
                    }
                    onClick={handleSave}
                  >
                    {t("common:save")}
                  </IconButton>
                </div>
              ) : (
                <IconButton Icon={FaEdit} onClick={handleEdit}>
                  {t("wiki:edit")}
                </IconButton>
              )}
              <div
                className="absolute shadow-top w-screen top-0 h-1"
                style={{ left: "-3px" }}
              ></div>
            </div>
          ) : null}
        </div>
      </div>
      <VersionList
        slugs={slugs}
        showsVersionList={showsVersionsList}
        setShowsVersionList={setShowsVersionsList}
      />
    </>
  );
};
