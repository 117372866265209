import React, { useCallback, useState, JSX } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus, FaSave, FaTrashAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

// @ts-ignore
import imageIcon from "assets/images/onepager/image-icon.svg";
// @ts-ignore
import textIcon from "assets/images/onepager/text-icon.svg";

import { Modal } from "components/modal";
import { IconButton, SmallIconButton } from "components/button";
import { validateMultilangFields } from "helpers/validateBlockFields";

import { FullyClickableDropdownButton } from "components/dropdownButton";

import { toMultiLangField } from "components/editVersioned/genericForm";
import { LayoutType, LayoutStructure } from "./jokerUtils";
import * as JokerUtils from "./jokerUtils";
import { IoSwapHorizontal } from "react-icons/io5";
import { ImMoveDown, ImMoveUp } from "react-icons/im";
import { TextField } from "components/form/textField";
import { useConfirmation } from "helpers/useConfirmation";
import { LinksField } from "components/form/linksField";

type ElementsDefinition = Partial<Record<string, ElementDefinition<any>>>;

type ElementDefinition<T> = {
  renderer: (props: any) => React.FC<any> | null | JSX.Element;
  multiLang?: boolean;
  getData: (value: T) => any;
  setData: (data: any, value: T) => any;
  onBeforeElementSave: (data: any) => any;
};

const TwoColumn = (props) => {
  const { value, ...otherProps } = props;
  const swapTwoColumnElements = props.swapTwoColumnElements;
  const [[leftIndex, left], [rightIndex, right]] = value;

  return (
    <div className="flex items-center w-full space-x-2">
      <div className="w-full h-full py-2 text-sm border border-gray-300 border-solid rounded-md">
        <GenericField {...otherProps} value={left} index={leftIndex} />
      </div>
      <SmallIconButton
        Icon={IoSwapHorizontal}
        onClick={swapTwoColumnElements}
      />
      <div className="w-full h-full py-2 text-sm border border-gray-300 border-solid rounded-md">
        <GenericField {...otherProps} value={right} index={rightIndex} />
      </div>
    </div>
  );
};

const Single = (props) => {
  const { value, ...otherProps } = props;
  const [index, data] = value;

  return (
    <div className="w-full h-full py-2 border border-gray-300 border-solid rounded-md">
      <GenericField {...otherProps} value={data} index={index} />
    </div>
  );
};

const EmptyField = ({ setElement, index, value, elementsDefinition }) => {
  const { t } = useTranslation("onepager");

  const options = Object.keys(elementsDefinition).map((key) => ({
    key,
    value: key,
    text: t(`jokerBlock.${key}` as any),
    onClick: (t) => {
      setElement(index, { ...value, type: t });
    },
  }));
  return (
    <div
      className="flex flex-col items-center pt-16"
      style={{ minHeight: "12rem" }}
    >
      <FullyClickableDropdownButton options={options}>
        <FaPlus className="mr-2" /> {t("jokerBlock.chooseElement")}
      </FullyClickableDropdownButton>
    </div>
  );
};

const GenericField = ({
  elementsDefinition,
  language,
  supportedLanguage,
  setElement,
  value,
  index,
}) => {
  const element = elementsDefinition[value.type];
  const Field = element?.multiLang
    ? useCallback(toMultiLangField(element.renderer), [])
    : element?.renderer;
  if (value.type === "empty") {
    const props = { elementsDefinition, index, value, setElement };
    return <EmptyField {...props} />;
  }

  return (
    <div
      className="flex items-center justify-center h-full"
      style={{ minHeight: "12rem" }}
    >
      <Field
        value={element.getData(value)}
        onChange={(v) => setElement(index, element.setData(value, v))}
        language={language}
        supportedLanguage={supportedLanguage}
      />
    </div>
  );
};

const layoutElements: {
  [K in LayoutType]: React.FC<any>;
} = {
  twoColumn: TwoColumn,
  single: Single,
};

type ModalProps = {
  block: any;
  showEdit: boolean;
  supportedLanguage: string;
  language: string;
  onClose: () => void;
  onSave: (block: any) => Promise<any>;
};

export const editJockerBlockModal =
  (elementsDefinition: ElementsDefinition) =>
  ({
    block,
    showEdit,
    supportedLanguage,
    language,
    onClose,
    onSave,
  }: ModalProps): JSX.Element => {
    const { t } = useTranslation(["onepager", "common", "error"]);

    const [layoutStructure, setLayoutStructure] = useState<LayoutStructure>({
      layout: [...(block.layout ?? [])],
      elements: [...(block.elements ?? [])],
    });
    const [navigationTitle, setNavigationTitle] = useState(
      block.navigationTitle,
    );
    const [externalLinks, setExternalLinks] = useState(block.externalLinks);

    const insertTemplate = (template, index) => {
      setLayoutStructure((layout) =>
        JokerUtils.insertTemplate(template, index, layout),
      );
    };

    const setElement = (index, value) => {
      setLayoutStructure((layout) =>
        JokerUtils.setElement(index, value, layout),
      );
    };

    const deleteLayout = (layoutId) => {
      setLayoutStructure((layout) =>
        JokerUtils.deleteLayoutBlock(layoutId, layout),
      );
    };

    const swapTwoColumnElements = (layoutId) => {
      setLayoutStructure((layout) =>
        JokerUtils.swapTwoColumnElements(layoutId, layout),
      );
    };

    const moveUpElements = (layoutId) => {
      setLayoutStructure((layoutStructure) =>
        JokerUtils.moveElementUp(layoutId, layoutStructure),
      );
    };

    const moveDownElements = (layoutId) => {
      setLayoutStructure((layoutStructure) =>
        JokerUtils.moveElementDown(layoutId, layoutStructure),
      );
    };

    const [invalidField, setInvalidField] = useState<string | null>(null);
    const [imageError, setImageError] = useState(false);

    const ValidationWarning = (): JSX.Element => {
      return (
        <div className="block mt-1 mr-4 font-medium text-red-500">
          {t("common:isRequired", {
            field: t(`common:${invalidField}` as any),
          })}
        </div>
      );
    };

    const ImageErrorWarning = (): JSX.Element => {
      return (
        <div className="block mt-1 mr-4 font-medium text-red-500">
          {t("error:imageError")}
        </div>
      );
    };

    useConfirmation(showEdit);

    return (
      <Modal
        title={
          <div className="flex flex-row p-8 text-2xl font-medium">
            <div className="mr-3 text-gray-400">{t("onepager:edit")}:</div>
            <div className="text-red-500">
              {t("onepager:editBlockTitles.joker")}
            </div>
          </div>
        }
        open={showEdit}
        close={onClose}
        closeOnClick={false}
        size="big"
        actions={
          <div className="flex flex-row space-x-2">
            {invalidField ? <ValidationWarning /> : null}
            {imageError ? <ImageErrorWarning /> : null}
            <IconButton type="secondary" Icon={IoMdClose} onClick={onClose}>
              {t("common:cancel")}
            </IconButton>
            <IconButton
              Icon={FaSave}
              onClick={() => {
                if (!validateMultilangFields(navigationTitle)) {
                  setInvalidField("navigationTitle");
                } else {
                  setInvalidField(null);
                  onSave({
                    ...block,
                    navigationTitle: navigationTitle,
                    externalLinks: externalLinks,
                    elements: layoutStructure.elements,
                    layout: layoutStructure.layout,
                  }).catch(() => setImageError(true));
                }
              }}
            >
              {t("common:save")}
            </IconButton>
          </div>
        }
      >
        <div className="px-8 m-4">
          <JokerForm
            elementsDefinition={elementsDefinition}
            setElement={setElement}
            currentLanguage={language}
            supportedLanguage={supportedLanguage}
            elements={layoutStructure.elements}
            layout={layoutStructure.layout}
            deleteLayout={deleteLayout}
            swapTwoColumnElements={swapTwoColumnElements}
            moveUpElements={moveUpElements}
            moveDownElements={moveDownElements}
            insertTemplate={insertTemplate}
            navigationTitle={navigationTitle}
            setNavigationTitle={setNavigationTitle}
            externalLinks={externalLinks}
            setExternalLinks={setExternalLinks}
          />
          <div
            className="flex flex-col items-center my-8 space-y-5"
            style={{ minHeight: "12rem" }}
          ></div>
        </div>
      </Modal>
    );
  };

const LayoutTemplates = ({ insertTemplate, setLayoutIndex, layoutIndex }) => {
  const templates = [
    ["richtext"],
    ["picture"],
    ["picture", "richtext"],
    ["richtext", "picture"],
    ["richtext", "richtext"],
    ["picture", "picture"],
  ].map((ts) => ts.map((t) => ({ type: t })));
  const { t } = useTranslation("onepager");

  return (
    <div className="flex flex-row justify-between w-full p-6 border rounded-md shadow-lg border-grey-300 hover:border-blue-500 hover:border-opacity-40">
      <div className="flex flex-col">
        <div className="mt-4 font-medium">{t("jokerBlock.layoutTitle")}</div>
        <div className="mb-6 text-xs text-gray-500 whitespace-pre-line">
          {t("jokerBlock.layoutText")}
        </div>
        <div className="flex flex-row flex-wrap cursor-pointer">
          {templates.map((template) => (
            <div
              key={JSON.stringify(template)}
              onClick={() => {
                insertTemplate(template, layoutIndex);
                setLayoutIndex(null);
              }}
              className="flex flex-row justify-center mb-4 mr-4 border rounded-md shadow-md h-28 w-28 border-grey-300 hover:border-blue-500 hover:border-opacity-40"
            >
              <div className="flex flex-row space-x-2">
                {template.map((item, index) => {
                  if (item.type == "richtext") {
                    return (
                      <img
                        key={index}
                        src={textIcon}
                        width="40"
                        alt="text-icon"
                      />
                    );
                  } else {
                    return (
                      <img
                        key={index}
                        src={imageIcon}
                        width="40"
                        alt="image-icon"
                      />
                    );
                  }
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <SmallIconButton
          type="secondary"
          Icon={IoMdClose}
          onClick={() => {
            setLayoutIndex(-1);
          }}
        />
      </div>
    </div>
  );
};

const JokerField = ({
  elementsDefinition,
  setElement,
  currentLanguage,
  supportedLanguage,
  layoutElement,
  deleteLayout,
  elements,
  swapTwoColumnElements,
  moveUpElements,
  moveDownElements,
  lastIndex,
  index,
}) => {
  const Field = layoutElements[layoutElement.type];

  const value =
    layoutElement.type === "twoColumn"
      ? [
          [layoutElement.indexes[0], elements[layoutElement.indexes[0]]],
          [layoutElement.indexes[1], elements[layoutElement.indexes[1]]],
        ]
      : layoutElement.type === "single"
        ? [layoutElement.index, elements[layoutElement.index]]
        : null;

  return (
    <div className="flex p-4 my-8 border rounded-md shadow-lg border-grey-300 hover:border-blue-500 hover:border-opacity-40">
      <div className="flex-grow break-all">
        <div className="flex flex-row">
          <div className="flex flex-col pr-2 space-y-2 shrink-0">
            <SmallIconButton
              Icon={ImMoveUp}
              disabled={index === 0}
              onClick={() => {
                moveUpElements(index);
              }}
            />
            <SmallIconButton
              Icon={ImMoveDown}
              disabled={index === lastIndex}
              onClick={() => {
                moveDownElements(index);
              }}
            />
          </div>
          <Field
            elementsDefinition={elementsDefinition}
            value={value}
            setElement={setElement}
            language={currentLanguage}
            supportedLanguage={supportedLanguage}
            swapTwoColumnElements={() =>
              swapTwoColumnElements(layoutElement.id)
            }
          />
          <div className="pl-2">
            <SmallIconButton
              Icon={FaTrashAlt}
              type={"warning"}
              onClick={() => {
                deleteLayout(layoutElement.id);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const JokerForm = ({
  elementsDefinition,
  currentLanguage,
  supportedLanguage,
  elements,
  layout,
  deleteLayout,
  setElement,
  swapTwoColumnElements,
  moveUpElements,
  moveDownElements,
  insertTemplate,
  navigationTitle,
  setNavigationTitle,
  externalLinks,
  setExternalLinks,
}) => {
  const [layoutIndex, setLayoutIndex] = useState(0);

  const { t } = useTranslation(["onepager"]);

  return (
    <div className="mt-6">
      <div className="mt-4">
        <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
          <div className="flex-1">
            <div className="font-medium">
              {t("onepager:jokerBlock.navigationTitle")}
            </div>
            <div className="text-xs text-gray-500 whitespace-pre-line">
              {t("onepager:jokerBlock.subTitleNavigation")}
            </div>
          </div>
          <div className="col-span-2">
            <TextField
              value={navigationTitle?.[currentLanguage] ?? ""}
              onChange={(newValue) => {
                setNavigationTitle({
                  ...navigationTitle,
                  [currentLanguage]: newValue,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="pt-6">
        {layoutIndex == 0 ? (
          <LayoutTemplates
            insertTemplate={insertTemplate}
            setLayoutIndex={setLayoutIndex}
            layoutIndex={layoutIndex}
          />
        ) : (
          <div className="flex justify-center">
            <IconButton
              Icon={FaPlus}
              className="text-gray-400 border-gray-400 hover:border-blue-500 hover:text-blue-500"
              onClick={() => {
                setLayoutIndex(0);
              }}
            >
              {t("onepager:addBlock")}
            </IconButton>
          </div>
        )}
        {layout.map((layoutElement, index) => {
          const props = {
            layoutElement,
            elements,
            currentLanguage,
            setElement,
            deleteLayout,
            elementsDefinition,
            swapTwoColumnElements,
          };
          return (
            <React.Fragment key={layoutElement.id}>
              <JokerField
                {...props}
                supportedLanguage={supportedLanguage}
                moveUpElements={moveUpElements}
                moveDownElements={moveDownElements}
                lastIndex={layout.length - 1}
                index={index}
              />
              {layoutIndex == index + 1 ? (
                <LayoutTemplates
                  insertTemplate={insertTemplate}
                  setLayoutIndex={setLayoutIndex}
                  layoutIndex={layoutIndex}
                />
              ) : (
                <div className="flex justify-center">
                  <IconButton
                    Icon={FaPlus}
                    className="text-gray-400 border-gray-400 hover:border-blue-500 hover:text-blue-500"
                    onClick={() => {
                      setLayoutIndex(index + 1);
                    }}
                  >
                    {t("onepager:addBlock")}
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>

      <div className="mt-6">
        <div className="mt-4">
          <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
            <div className="flex-1">
              <div className="font-medium">
                {t("onepager:jokerBlock.externalLinks")}
              </div>
              <div className="text-xs text-gray-500 whitespace-pre-line">
                {t("onepager:jokerBlock.subExternalLinks")}
              </div>
            </div>
            <div className="col-span-2">
              <LinksField
                value={externalLinks}
                onChange={(newValue) => {
                  setExternalLinks(newValue);
                }}
                language={currentLanguage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
