import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { useFuse } from "helpers/useFuse";
import Masonry from "react-masonry-css";

import { ROLES_KEY_ORDER } from "pages/project/team/helpers/roles";

import { TeamMember, PreviousTeamMember } from "pages/teamMember";
import { AggregatedProjectTeamMember } from "generated/graphql";

// HELPER COMPONENTS

export const getMostSeniorRoleIndex = (references: any[]): number => {
  const allRoles = references.map((r) => {
    if (r.role === null) return "role_0";
    else return r.role;
  });
  const rolesMatchedToIndex = allRoles.map((r) => ROLES_KEY_ORDER.indexOf(r));
  return Math.min(...rolesMatchedToIndex);
};

// MAIN COMPONENT

export const Team = ({
  team,
  filterText,
  projectId,
}: {
  team: AggregatedProjectTeamMember[];
  filterText: string;
  projectId: string;
}): JSX.Element => {
  const { t } = useTranslation("project");

  const sortTeamBySeniority = (a, b) => {
    const aMostImportantRole =
      a.references.length > 0
        ? getMostSeniorRoleIndex(a.references)
        : ROLES_KEY_ORDER.length;
    const bMostImportantRole =
      b.references.length > 0
        ? getMostSeniorRoleIndex(b.references)
        : ROLES_KEY_ORDER.length;
    if (aMostImportantRole > bMostImportantRole) return 1;
    if (aMostImportantRole < bMostImportantRole) return -1;
    else return a.employee.lastName.localeCompare(b.employee.lastName);
  };

  const filteredPeople = useFuse(team, filterText, {
    keys: ["employee.firstName", "employee.lastName"],
  });

  const previousPeople = filteredPeople
    ?.filter((p) => !!(p?.employee?.isFormerEmployee ?? true))
    ?.sort(
      (a, b) =>
        a.employee?.lastName?.localeCompare(b.employee?.lastName ?? "") ?? 0,
    );

  const activePeople = filteredPeople
    .filter((p: any) => !p?.employee?.isFormerEmployee)
    .sort(sortTeamBySeniority);

  const breakpointColumns = {
    default: 2,
    767: 1,
  };

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumns}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {activePeople?.map((member: any) => {
          return (
            <TeamMember projectId={projectId} key={member.id} member={member} />
          );
        })}
      </Masonry>
      {previousPeople.length > 0 ? (
        <div className="mt-8 text-xl text-blue-500">{t("exEmployees")}</div>
      ) : null}
      <div className="grid w-full grid-cols-2 gap-4 mt-4">
        {previousPeople?.map((member: any) => {
          return <PreviousTeamMember key={member.id} member={member} />;
        })}
      </div>
    </>
  );
};
