import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Menubar from "@radix-ui/react-menubar";

import AuthContext from "context/user";

export const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation("mainLanguages");
  const { user } = useContext(AuthContext);
  const options = [
    { value: "de", label: "DE" },
    { value: "fr", label: "FR" },
    { value: "it", label: "IT" },
    { value: "en", label: "EN" },
    user?.isContentAdmin ? { value: "cimode", label: "CI" } : null,
  ].filter((it) => it);

  const setLanguage = (language) => {
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  };

  return (
    <Menubar.Root className="hidden sm:block">
      <Menubar.Menu>
        <Menubar.Trigger className="focus-visible:ring-inset button focus bg-white hover:bg-gray-10">
          <span>{i18n.language?.toUpperCase()}</span>
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content
            align="start"
            sideOffset={5}
            alignOffset={-3}
            className="z-40 -mt-1 min-w-[8rem] border bg-white px-4 py-4 md:px-0 w-screen py-3 md:w-64"
          >
            {options.map((option: any) => {
              return (
                <Menubar.Item
                  key={option?.value}
                  className="flex items-center justify-between px-4 py-2 space-x-3 text-sm whitespace-nowrap text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                  role="menuitem"
                  onClick={() => {
                    setLanguage(option?.value ?? "de");
                  }}
                >
                  <div>{t(option?.value as any)}</div>
                </Menubar.Item>
              );
            })}
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
};
