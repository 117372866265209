import React, { useState, JSX } from "react";
import { useTranslation } from "react-i18next";
import Layout from "layout/layout";
import Select from "react-select";
import {
  SELECTABLE_APPLICATION_AREAS,
  SELECTABLE_SERVICES,
} from "pages/project/utils";
import { customStyles, i18nOptions } from "components/form/multiSelectField";
import { useLazyQuery, useApolloClient } from "@apollo/client";
import { FILTERED_PROJECT_REFERENCES_QUERY } from "queries/references";
import { wordExportLanguageOptions } from "helpers/languages";
import { Modal } from "components/modal";
import { MdFileDownload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { Button, IconButton } from "components/button";
import { FullyClickableDropdownButton } from "components/dropdownButton";
import toast from "react-hot-toast";

interface Reference {
  id: string;
  title: { de: string; en: string; fr: string; it: string };
  description: { de: string; en: string; fr: string; it: string };
  applicationAreas: string[];
  services: string[];
  availableLanguages: string[];
  projectId: string;
  isSelected: boolean;
}

// Imports for DOCX generation
import {
  useExportSingleReference,
  createReferencesDocument,
} from "pages/newProject/wordExport";
import { downloadWord } from "helpers/word/documents";
import { SINGLE_REFERENCE_WORD_QUERY } from "queries/newWord";

export const ExportReferences = (): JSX.Element => {
  const { t, i18n } = useTranslation([
    "common",
    "exportReferences",
    "abacusProjects",
    "languagesFull",
  ]);
  const apolloClient = useApolloClient();
  const { exportSingleReference, loading: exportLoading } =
    useExportSingleReference();
  const [selectedApplicationAreas, setSelectedApplicationAreas] = useState<
    string[]
  >([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [references, setReferences] = useState<Reference[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("de");
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [exportOnlyWithLanguage, setExportOnlyWithLanguage] =
    useState<boolean>(true);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [getFilteredReferences, { loading, error }] = useLazyQuery(
    FILTERED_PROJECT_REFERENCES_QUERY,
    {
      onCompleted: (data) => {
        if (data?.filteredProjectReferences) {
          setReferences(
            data.filteredProjectReferences.map((ref: any) => ({
              ...ref,
              isSelected: true, // Select all by default
            })),
          );
        }
      },
    },
  );

  const translationKey = "abacusProjects";

  const optionMap = (v: string) => ({
    value: v,
    label: t(`${translationKey}:${v}` as any, { lng: "de" }),
  });

  // Language options for export
  const languageOptions = wordExportLanguageOptions.map((languageOption) => ({
    ...languageOption,
    label: t(`languagesFull:${languageOption.key}` as any),
  }));
  const selectedLanguageOption = languageOptions?.find(
    (c) => c.key === selectedLanguage,
  );

  // Handle select all checkbox
  const toggleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setReferences(
      references.map((ref) => ({
        ...ref,
        isSelected: isChecked,
      })),
    );
  };

  // Handle individual reference selection
  const toggleReferenceSelection = (id: string) => {
    setReferences(
      references.map((ref) => ({
        ...ref,
        isSelected: ref.id === id ? !ref.isSelected : ref.isSelected,
      })),
    );
  };

  // Get selected references
  const selectedReferences = references.filter((ref) => ref.isSelected);

  // Get current language from i18n
  const currentLang = i18n.language.substring(0, 2);

  // Function to determine if a language is available for a reference
  // For de-DE, we use the same availability as de since they share the same content
  const hasLanguage = (ref: Reference, lang: string) => {
    const lookupLang = lang === "de-DE" ? "de" : lang;
    return ref.availableLanguages
      ? ref.availableLanguages.includes(lookupLang)
      : false;
  };

  // Handle fetching references with potentially empty filters
  const handleGetReferences = () => {
    // Allow search with empty filters
    getFilteredReferences({
      variables: {
        filter: {
          application_area_ids: selectedApplicationAreas,
          service_ids: selectedServices,
        },
      },
    });
  };

  return (
    <Layout title={t("exportReferences:title")}>
      <div className="px-3 mx-auto text-gray-800 max-w-screen-tbf">
        <div className="p-6 bg-white border rounded-md shadow-sm mb-6">
          <p className="text-lg mb-6">{t("exportReferences:description")}</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="font-medium mb-2">
                {t("exportReferences:applicationAreas")}
              </h3>
              <div className="p-4 bg-gray-100 rounded-md">
                <div className="text-sm">
                  <Select
                    isMulti
                    styles={customStyles}
                    options={SELECTABLE_APPLICATION_AREAS.map(optionMap)}
                    isDisabled={loading}
                    value={selectedApplicationAreas.map(optionMap) as any}
                    {...i18nOptions(t)}
                    onChange={(newValues) => {
                      setSelectedApplicationAreas(
                        (newValues as any).map((v) => v.value),
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-2">
                {t("exportReferences:services")}
              </h3>
              <div className="p-4 bg-gray-100 rounded-md">
                <div className="text-sm">
                  <Select
                    isMulti
                    styles={customStyles}
                    options={SELECTABLE_SERVICES.map(optionMap)}
                    isDisabled={loading}
                    value={selectedServices.map(optionMap) as any}
                    {...i18nOptions(t)}
                    onChange={(newValues) => {
                      setSelectedServices(
                        (newValues as any).map((v) => v.value),
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-between">
            <div>
              <Button primary onClick={handleGetReferences} disabled={loading}>
                {loading
                  ? t("common:loading")
                  : t("exportReferences:getReferences")}
              </Button>
            </div>

            {references.length > 0 && (
              <div className="flex items-center space-x-2">
                <FullyClickableDropdownButton
                  disabled={
                    selectedReferences.length === 0 ||
                    loadingExport ||
                    exportLoading
                  }
                  options={languageOptions.map((lang) => ({
                    text: lang.label,
                    key: lang.key,
                    value: lang.key,
                    icon: <MdFileDownload />,
                    onClick: (langKey) => {
                      setSelectedLanguage(langKey || "de");
                      setShowExportModal(true);
                    },
                  }))}
                >
                  <div className="flex items-center">
                    <MdFileDownload className="mr-2 text-lg" />
                    {loadingExport || exportLoading
                      ? t("common:loading")
                      : t("exportReferences:exportDocx")}
                  </div>
                </FullyClickableDropdownButton>
              </div>
            )}
          </div>

          {error && (
            <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-md">
              {t("common:error")}
            </div>
          )}

          {references.length > 0 && (
            <div className="mt-8">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">
                  {t("exportReferences:foundReferences", {
                    count: references.length,
                  })}
                </h3>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="select-all"
                    className="mr-2 h-4 w-4 text-red-500 focus:ring-red-500 border-gray-300 rounded"
                    onChange={toggleSelectAll}
                    checked={
                      references.length > 0 &&
                      references.every((ref) => ref.isSelected)
                    }
                  />
                  <label htmlFor="select-all">
                    {t("exportReferences:selectAll")}
                  </label>
                </div>
              </div>

              <div className="overflow-x-auto">
                <table className="min-w-full table-fixed">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 sticky top-0 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("exportReferences:select")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 sticky top-0 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("exportReferences:referenceTitle")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 sticky top-0 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        DE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 sticky top-0 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        FR
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 sticky top-0 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        IT
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 sticky top-0 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        EN
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {references.map((reference) => (
                      <tr key={reference.id}>
                        <td className="px-6 py-4 w-12 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={reference.isSelected}
                            onChange={() =>
                              toggleReferenceSelection(reference.id)
                            }
                            className="focus:ring-red-500 h-4 w-4 text-red-500 border-gray-300 rounded"
                          />
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-red-500 max-w-xs truncate">
                            <a
                              href={`/project/${reference.projectId}/references#${reference.id}`}
                              className="text-red-500 hover:text-red-700 hover:underline"
                              target="_blank"
                              rel="noopener noreferrer"
                              title={
                                reference.title
                                  ? reference.title[
                                      currentLang as keyof typeof reference.title
                                    ] ||
                                    reference.title.de ||
                                    reference.title.en ||
                                    reference.title.fr ||
                                    reference.title.it ||
                                    t("exportReferences:untitled")
                                  : t("exportReferences:untitled")
                              }
                            >
                              {reference.title
                                ? reference.title[
                                    currentLang as keyof typeof reference.title
                                  ] ||
                                  reference.title.de ||
                                  reference.title.en ||
                                  reference.title.fr ||
                                  reference.title.it ||
                                  t("exportReferences:untitled")
                                : t("exportReferences:untitled")}
                            </a>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center justify-center">
                            {hasLanguage(reference, "de") ? (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                ✓
                              </span>
                            ) : (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                -
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center justify-center">
                            {hasLanguage(reference, "fr") ? (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                ✓
                              </span>
                            ) : (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                -
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center justify-center">
                            {hasLanguage(reference, "it") ? (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                ✓
                              </span>
                            ) : (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                -
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center justify-center">
                            {hasLanguage(reference, "en") ? (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                ✓
                              </span>
                            ) : (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                -
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={showExportModal}
        close={() => setShowExportModal(false)}
        title={
          <div className="px-6 py-8 text-2xl font-medium text-red-500">
            {t("exportReferences:exportDocx")}
          </div>
        }
        actions={
          <div className="flex justify-end space-x-2">
            <IconButton
              type="secondary"
              Icon={IoMdClose}
              onClick={() => setShowExportModal(false)}
            >
              {t("common:cancel")}
            </IconButton>
            <IconButton
              Icon={MdFileDownload}
              onClick={async () => {
                setShowExportModal(false);
                // Filter references based on language availability if needed
                const refsToExport = exportOnlyWithLanguage
                  ? selectedReferences.filter((ref) =>
                      hasLanguage(ref, selectedLanguage),
                    )
                  : selectedReferences;

                // Show loading state
                setLoadingExport(true);

                try {
                  // Make sure we have references to export
                  if (refsToExport.length === 0) {
                    return;
                  }

                  if (refsToExport.length === 1) {
                    // For a single reference, use the existing function
                    exportSingleReference(refsToExport[0].id, selectedLanguage);
                    toast.success(t("exportReferences:downloadDocx" as any));
                  } else {
                    // For multiple references, we need to:
                    // 1. Get the IDs of all selected references
                    const referenceIds = refsToExport.map((ref) => ref.id);

                    // 2. Use the imported query

                    // 3. Fetch each reference with the correct query format
                    const fetchedReferences = await Promise.all(
                      referenceIds.map(async (id) => {
                        const { data } = await apolloClient.query({
                          query: SINGLE_REFERENCE_WORD_QUERY,
                          variables: { id },
                          fetchPolicy: "network-only",
                        });
                        return data.reference;
                      }),
                    );

                    // Filter out any null/undefined references
                    const validReferences = fetchedReferences.filter(
                      (ref) => ref,
                    );

                    // 4. Create document with properly fetched references
                    if (validReferences.length > 0) {
                      console.log("Exporting references:", validReferences);

                      // Add page breaks between references by creating a modified array
                      const referencesWithPageBreaks = validReferences.map(
                        (ref, index) => ({
                          ...ref,
                          _addPageBreak: index < validReferences.length - 1, // Add page break flag for all except last
                        }),
                      );

                      // Create a document with all references
                      const document = await createReferencesDocument(
                        t,
                        referencesWithPageBreaks,
                        selectedLanguage,
                      );

                      // Generate a title for the document
                      const formattedDate = new Date()
                        .toISOString()
                        .split("T")[0];
                      const langCode = selectedLanguage.toUpperCase();
                      const title = `References_Export_${langCode}_${formattedDate}`;

                      // Download the document
                      await downloadWord(title, document);

                      // Show success message
                      toast.success(
                        t("exportReferences:downloadDocx" as any) +
                          ` (${validReferences.length} references)`,
                      );
                    } else {
                      toast.error(
                        t("exportReferences:noReferencesFound") ||
                          t("common:error"),
                      );
                    }
                  }
                } catch (error) {
                  console.error("Error generating references document:", error);
                  toast.error(t("common:error"));
                } finally {
                  setLoadingExport(false);
                }
              }}
              disabled={loadingExport || exportLoading}
            >
              {loadingExport || exportLoading
                ? t("common:loading")
                : t("exportReferences:downloadDocx" as any)}
            </IconButton>
          </div>
        }
      >
        <div className="p-6 space-y-6">
          <div>
            <h3 className="font-medium mb-2">
              {t("exportReferences:exportSummary" as any)}
            </h3>
            <p>
              {t("exportReferences:exportLanguage" as any)}:{" "}
              <span className="font-semibold">
                {selectedLanguageOption?.label}
              </span>
            </p>
            <p>
              {t("exportReferences:selectedReferences" as any)}:{" "}
              <span className="font-semibold">{selectedReferences.length}</span>
            </p>
            <p>
              {t("exportReferences:availableInLanguage" as any)}:{" "}
              <span className="font-semibold">
                {
                  selectedReferences.filter((ref) =>
                    hasLanguage(ref, selectedLanguage),
                  ).length
                }
              </span>
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="export-only-with-language"
              checked={exportOnlyWithLanguage}
              onChange={(e) => setExportOnlyWithLanguage(e.target.checked)}
              className="h-4 w-4 text-red-500 focus:ring-red-500 border-gray-300 rounded"
            />
            <label htmlFor="export-only-with-language" className="text-sm">
              {t("exportReferences:exportOnlyWithLanguage" as any)}
            </label>
          </div>

          {exportOnlyWithLanguage &&
            selectedReferences.filter((ref) =>
              hasLanguage(ref, selectedLanguage),
            ).length === 0 && (
              <div className="p-4 bg-yellow-100 text-yellow-700 rounded-md">
                {t("exportReferences:noReferencesWithLanguage" as any)}
              </div>
            )}
        </div>
      </Modal>
    </Layout>
  );
};
