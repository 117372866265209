import React, { useState, JSX } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "wouter";
import {
  FaEdit,
  FaCalendarAlt,
  FaBuilding,
  FaKey,
  FaDownload,
  FaExchangeAlt,
  FaExclamationTriangle,
  FaStar,
  FaRegStar,
  FaBook,
} from "react-icons/fa";
import { HiLink } from "react-icons/hi";
import { IoMdSwap, IoMdClose } from "react-icons/io";
import { RiBarChartHorizontalFill } from "react-icons/ri";
import { NewProject, NewReference, Client, Employee } from "generated/graphql";
import { useMultiLang } from "helpers/multiLang";

import {
  EDIT_REFERENCE_MUTATION,
  ADD_STARRED_REFERENCE_MUTATION,
  REMOVE_STARRED_REFERENCE_MUTATION,
} from "queries/references";
import { EditReferenceModal } from "components/editVersioned/editReference";
import { EditWarningModal } from "components/editVersioned/editReference";
import { ImageModal } from "components/imageModal";
import { IconButton } from "components/button";

import { Popup } from "components/popup";

import { useTranslation } from "react-i18next";
import { ItemListPlain } from "pages/newEmployee/tags";
import { shortenSiaOrHoai } from "./utils";
import { Modal } from "components/modal";
import { ProjectsField } from "components/form/projectsField";
import ReactMarkdown from "react-markdown";
import { MdInfoOutline, MdInfo } from "react-icons/md";
import { DropdownButton } from "components/dropdownButton";
import { useExportSingleReference } from "pages/newProject/wordExport";
import { wordExportLanguageOptions } from "helpers/languages";
import { formatProjectId } from "pages/newProject/header";
import { selectReferenceData } from "helpers/getReferenceData";
import { findAndReturnReferenceTitle } from "helpers/findAndReturnReferenceTitle";
import { AiFillEyeInvisible } from "react-icons/ai";
import { IoShareSocialOutline } from "react-icons/io5";
import classNames from "classnames";

// HELPER COMPONENTS

const CopyCurrentURL = ({ id }: { id: string | undefined }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Popup content={copied ? "Copied!" : "Copy URL to clipboard"}>
      <button
        type="button"
        onClick={() => {
          const currentUrl = `${window.location.href}#${id ?? ""}`;
          navigator.clipboard.writeText(currentUrl);
          setCopied(true);

          setInterval(() => {
            setCopied(false);
          }, 2000);
        }}
        className="px-2 py-1 text-sm flex items-center text-blue-500 border border-grey-300 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40 rounded"
      >
        <IoShareSocialOutline />
      </button>
    </Popup>
  );
};

const DeletedOverlay = () => {
  const { t } = useTranslation();

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50">
      <div className="flex items-center justify-center h-full text-lg text-center text-gray-500">
        {t("deleted")}
      </div>
    </div>
  );
};

export const ParentReferenceLink = ({
  reference,
}: {
  reference: Partial<NewReference>;
}): JSX.Element | null => {
  const { t } = useTranslation("project");
  const m = useMultiLang();

  return reference?.parentReference ? (
    <Link
      className="group flex items-center my-4"
      href={`/project/${reference.projectId}/references#${reference.parentReferenceId}`}
    >
      <HiLink />
      <div className="ml-2 text-sm text-gray-700 group-hover:text-blue-500">
        <div>{m(reference?.parentReference?.title, t("noTitle"))}</div>
        <div className="text-xs">{t("linkProjectReferences")}</div>
      </div>
    </Link>
  ) : null;
};

export const MetadataReference = ({
  from,
  to,
  siaPhases,
  hoaiPhases,
  client,
  clientHidden = false,
  showClientIcon,
  primaryPhase,
}: {
  to: string | null;
  from: string | null;
  siaPhases: string[];
  hoaiPhases: string[];
  client?: Partial<Client>;
  showClientIcon?: boolean;
  clientHidden?: boolean;
  primaryPhase?: string;
}): JSX.Element => {
  const { t } = useTranslation(["project", "form"]);

  const duration =
    to && to.length > 0
      ? `${from?.slice(0, 4) ?? ""}–${to.slice(0, 4)}`
      : `${from?.slice(0, 4) ?? ""}`;

  const phases = shortenSiaOrHoai(
    primaryPhase === "sia",
    siaPhases,
    hoaiPhases,
  );

  return (
    <div className="flex flex-wrap items-center overflow-hidden break-none">
      <Popup content={t("project:workDuration")}>
        <div className="flex items-center mt-1 mr-4 text-gray-500">
          <FaCalendarAlt />
          <div className="ml-2 text-sm">
            {duration.length > 0 ? duration : t(`project:notAvailable`)}
          </div>
        </div>
      </Popup>

      <Popup content={t("project:phases")}>
        <div className="flex items-center mt-1 mr-4 text-gray-500">
          <RiBarChartHorizontalFill />
          <div className="ml-2 text-sm">
            {phases.length > 0 ? phases : t(`project:notAvailable`)}
          </div>
        </div>
      </Popup>

      {showClientIcon ? (
        <>
          {client?.id ? (
            <Link
              className="flex items-center mt-1 space-x-2 text-sm text-gray-500 hover:text-blue-500"
              href={`/client/${client.id}`}
            >
              <FaBuilding />
              <div
                style={{ maxWidth: "8rem" }}
                className="mt-1 overflow-hidden leading-none overflow-ellipsis whitespace-nowrap"
              >
                {client.name || ""}
              </div>
            </Link>
          ) : client ? (
            <span className="flex items-center mt-1 space-x-2 text-sm text-gray-500">
              <FaBuilding />
              <div
                style={{ maxWidth: "8rem" }}
                className="mt-1 overflow-hidden leading-none overflow-ellipsis whitespace-nowrap"
              >
                {client.name || ""}
              </div>
            </span>
          ) : (
            <div className="flex items-center mt-1 space-x-2 text-sm text-gray-500">
              <FaBuilding />
              <div
                style={{ maxWidth: "8rem" }}
                className="overflow-hidden leading-none overflow-ellipsis whitespace-nowrap"
              >
                {t(`project:notAvailable`)}
              </div>
            </div>
          )}
          {client && clientHidden ? (
            <Popup content={t("form:fieldDescription.project.clientHidden")}>
              <div className="flex items-center mt-1 mr-4 text-gray-500">
                <AiFillEyeInvisible />
                <div className="ml-2 text-sm">
                  {t("form:fieldName.project.clientHidden")}
                </div>
              </div>
            </Popup>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export const DescriptionReference = ({
  reference,
}: {
  reference: Partial<NewReference>;
}): JSX.Element => {
  const { t } = useTranslation();
  const m = useMultiLang();

  const [showTruncated, setShowTruncated] = useState(true);
  const text = m(reference?.description);
  const maxTextLength = 450;
  let truncatedText = text;
  let displayMoreButton = false;
  if (text?.length > maxTextLength) {
    truncatedText = `${text.slice(0, maxTextLength)} ...`;
    displayMoreButton = true;
  }

  return (
    <>
      <div className="mt-4 text-xs text-gray-500 markdown-small">
        <ReactMarkdown>{showTruncated ? truncatedText : text}</ReactMarkdown>
      </div>
      {showTruncated && displayMoreButton ? (
        <button
          className="block w-full mt-4 text-sm text-center text-gray-500 hover:text-black focus:outline-none"
          onClick={() => {
            setShowTruncated(false);
          }}
        >
          {`${t("more")} ...`}
        </button>
      ) : null}
    </>
  );
};

export const LastUpdateReference = ({
  reference,
}: {
  reference: Partial<NewReference>;
}): JSX.Element => {
  const { t } = useTranslation(["project", "common"]);
  const formattedDate = new Date(
    reference.approvedAt ?? reference.insertedAt ?? 0,
  ).toLocaleDateString("de-CH");

  return (
    <div className="text-xs text-gray-500">
      {t(`common:lastUpdate`)} {formattedDate}
    </div>
  );
};

const MigrateProjectModal = ({
  isOpen,
  close,
  parentProjectId,
  referenceId,
}): JSX.Element => {
  const { t } = useTranslation(["project", "common", "form"]);
  const [editMutation] = useMutation(EDIT_REFERENCE_MUTATION);
  const [selectedProjectId, setSelectedProjectId] = useState(parentProjectId);
  const migrateReference = () => {
    editMutation({
      variables: {
        id: referenceId,
        fields: { projectId: selectedProjectId },
      },
    });
    close();
  };

  return (
    <Modal
      actions={
        <div className="flex flex-row-reverse justify-between w-full">
          <div className="flex items-center px-6 space-x-2">
            <IconButton type="secondary" Icon={IoMdClose} onClick={close}>
              {t("common:close")}
            </IconButton>
            <IconButton
              disabled={!selectedProjectId}
              Icon={IoMdSwap}
              onClick={migrateReference}
            >
              {t(`project:migrateReference`)}
            </IconButton>
          </div>
        </div>
      }
      open={isOpen}
      close={close}
      title={
        <span className="inline-block px-6 py-8 text-2xl font-medium text-red-500">
          {t(`project:migrateReference`)}
        </span>
      }
    >
      <div
        className="grid gap-4 px-6 my-8"
        style={{ gridTemplateColumns: "1fr 2fr" }}
      >
        <div className="flex flex-col justify-between break-word">
          <div className="font-medium">
            {t("form:fieldName.reference.projectId")}
          </div>
          <div className="text-xs text-gray-500">
            {t("form:fieldDescription.reference.projectId")}
          </div>
        </div>
        <ProjectsField
          onChange={(args) => {
            setSelectedProjectId(args);
          }}
          value={selectedProjectId}
          entity={{}}
          disabled={false}
          multiple={false}
        />
      </div>
    </Modal>
  );
};

export const Overlay = ({
  reference,
  parentProject,
}: {
  reference: Partial<NewReference>;
  parentProject: Partial<NewProject> | undefined;
}): JSX.Element => {
  const { t } = useTranslation(["project", "common"]);
  const m = useMultiLang();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="absolute top-0 left-0 z-10 block w-full h-full bg-white bg-opacity-90">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="px-4 text-sm text-center">
          <strong>{t("common:warning")}: </strong>
          {t("project:migrateReferenceInfo", {
            projectName: m(parentProject?.name, `(${parentProject?.id ?? ""})`),
          })}
        </div>
        <div className="mt-4">
          {isOpen ? (
            <MigrateProjectModal
              isOpen={isOpen}
              close={() => setIsOpen(false)}
              parentProjectId={parentProject?.id}
              referenceId={reference?.id}
            />
          ) : null}

          <IconButton
            Icon={IoMdSwap}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {t(`project:migrateReference`)}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export const needToMigrate = (
  reference: Partial<NewReference>,
): boolean | null | undefined => {
  return (
    reference?.project?.isUnlisted &&
    reference?.project?.parentProjects &&
    reference.project.parentProjects.length > 0
  );
};

const DraftProcessInfo = ({ id, isReadyForReview }) => {
  const { t } = useTranslation(["project", "common"]);
  const [editReference] = useMutation(EDIT_REFERENCE_MUTATION);
  const setReadyForReview = (isReady) => {
    editReference({
      variables: { id, fields: { isReadyForReview: isReady } },
    });
  };
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <div className="flex items-center my-4 text-gray-500">
        <div className="m-2">
          <MdInfoOutline className="w-6 h-6" />
        </div>
        <div className="text-sm shrink">
          {isReadyForReview
            ? t("project:referenceInDrafts")
            : t("project:referenceWaitingForReadyForReview")}
          {isReadyForReview ? null : (
            <IconButton
              className="mt-2"
              Icon={FaExchangeAlt}
              onClick={() => setShowConfirmation(true)}
            >
              {t("project:setReadyForReview")}
            </IconButton>
          )}
        </div>
      </div>
      <Modal
        size="small"
        title={
          <div className="px-6 py-8 text-2xl font-medium text-red-500">
            {t("project:setReadyForReview")}
          </div>
        }
        open={showConfirmation}
        close={() => setShowConfirmation(false)}
        actions={
          <div className="flex flex-row space-x-2">
            <IconButton
              type="secondary"
              Icon={IoMdClose}
              onClick={() => setShowConfirmation(false)}
            >
              {t("common:cancel")}
            </IconButton>
            <IconButton
              type="soft-warning"
              Icon={FaExchangeAlt}
              onClick={() => {
                setReadyForReview(true);
                setShowConfirmation(false);
              }}
            >
              {t("common:confirm")}
            </IconButton>
          </div>
        }
      >
        <div className="px-6 my-8">
          {t("common:confirmationSetReadyForReview")}
        </div>
      </Modal>
    </>
  );
};

export const ReferencesContent = ({
  isDraft,
  isReadyForReview,
  reference,
}: {
  isDraft: boolean;
  isReadyForReview: boolean;
  reference: Partial<NewReference>;
}): JSX.Element => {
  const { t } = useTranslation(["project", "abacusProjects"]);
  const getReferenceData = (category) =>
    selectReferenceData(reference, category) ?? [];

  const siaPhases = getReferenceData("siaPhases");
  const hoaiPhases = getReferenceData("hoaiPhases");
  const services = getReferenceData("services").map((key) =>
    t(`abacusProjects:${key}` as any),
  );
  const from = getReferenceData("from");
  const to = getReferenceData("to");
  const applicationAreas = getReferenceData("applicationAreas").map((key) =>
    t(`abacusProjects:${key}` as any),
  );
  const isEmployeeReference = !!reference.employeeId;
  const client = reference?.privateReferenceProjectClientName
    ? { name: reference?.privateReferenceProjectClientName }
    : reference?.project?.client;

  return (
    <>
      <div className="relative" id={reference.id}>
        <div className="my-4">
          <MetadataReference
            from={!from && !to ? t("project:notAvailable") : from}
            to={to}
            siaPhases={siaPhases}
            hoaiPhases={hoaiPhases}
            client={client ?? undefined}
            clientHidden={reference?.project?.clientHidden ?? false}
            showClientIcon={true}
            primaryPhase={
              reference?.primaryPhase ??
              reference?.privateReferenceProjectPrimaryPhase
            }
          />
        </div>
        <div className="mt-2">
          <ItemListPlain items={applicationAreas} />
          <ItemListPlain className="mt-2" items={services} />
        </div>

        <DescriptionReference reference={reference} />
        {isDraft ? (
          <DraftProcessInfo
            id={reference.id}
            isReadyForReview={isReadyForReview}
          />
        ) : null}

        <ParentReferenceLink reference={reference} />
        {reference.deletedAt ? <DeletedOverlay /> : null}
      </div>

      {(reference?.internalNote?.length ?? 0) > 0 ? (
        <div className="ml-2 relative">
          <MdInfo className="absolute left-0 h-6 w-6 text-blue-500" />
          <div className="ml-8 text-sm text-gray-500 truncate">
            {reference.internalNote}
          </div>
        </div>
      ) : null}

      {isEmployeeReference ? (
        <ReferenceDataWarning reference={reference} />
      ) : null}
      <div className="flex justify-between mt-4">
        <LastUpdateReference reference={reference} />
      </div>
    </>
  );
};

const MainReferenceIndicator = () => {
  const { t } = useTranslation("reference");

  return (
    <div className="absolute z-10 flex items-center px-2 py-1 m-2 space-x-2 bg-white border border-white rounded shadow">
      <FaKey size={12} />
      <div className="text-xs">{t("mainReference")}</div>
    </div>
  );
};

const StarredReferenceIndicator = ({
  isStarred = false,
  referenceId,
}: {
  isStarred: boolean | undefined;
  referenceId: string;
}) => {
  const [addStarredMutation] = useMutation(ADD_STARRED_REFERENCE_MUTATION);
  const [removeStarredMutation] = useMutation(
    REMOVE_STARRED_REFERENCE_MUTATION,
  );

  const addToFavorite = () => {
    addStarredMutation({ variables: { id: referenceId } });
  };

  const removeFromFavorite = () => {
    removeStarredMutation({ variables: { id: referenceId } });
  };

  const backdropShadow = {
    filter:
      "drop-shadow(0 4px 3px rgb(0 0 0 / 0.57)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.42))",
  };

  return (
    <div
      className={classNames([
        "absolute z-10 p-2 right-0 text-white",
        isStarred ? "" : "hidden group-hover:block",
      ])}
    >
      {isStarred ? (
        <FaStar
          style={backdropShadow}
          onClick={removeFromFavorite}
          className="fill-current cursor-pointer w-6 h-6"
          aria-hidden="true"
        />
      ) : (
        <FaRegStar
          style={backdropShadow}
          onClick={addToFavorite}
          className="fill-current cursor-pointer w-6 h-6"
        />
      )}
    </div>
  );
};

export const ReferenceItemView = ({
  reference,
  currentEmployee,
  isEmployeePartOfTeam,
}: {
  reference: Partial<NewReference>;
  currentEmployee: Partial<Employee>;
  isEmployeePartOfTeam: boolean;
}): JSX.Element => {
  const { t } = useTranslation([
    "project",
    "abacusProjects",
    "searchHighlights",
    "common",
    "wordExport",
    "languagesFull",
  ]);
  const m = useMultiLang();
  const isUnapprovedNew = !reference.draft && !reference.approvedAt;
  const isDraft = reference.draft ? true : false;
  const isReadyForReview = isUnapprovedNew
    ? (reference.isReadyForReview ?? false)
    : (reference.draft?.isReadyForReview ?? false);
  const displayReference = reference.draft ?? reference;
  const picture = selectReferenceData(displayReference, "picture");
  const formattedProjectId = formatProjectId(
    displayReference?.project?.abacusProjectId
      ? displayReference?.project?.abacusProjectId
      : `9${displayReference?.project?.archivedProjectId}000`,
  );

  // Only employee reference have role
  const role = displayReference.employeeId
    ? displayReference.roles
      ? displayReference.roles
          .map((r) =>
            r.referenceRoleId
              ? m(r.referenceRole?.name, "")
              : (r.newText ?? ""),
          )
          .join(", ")
      : t("abacusProjects:role_0")
    : null;

  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showEditEmployeeReferenceModal, setShowEditEmployeeReferenceModal] =
    useState(false);
  const [fullImage, setFullImage] = useState(false);
  const { exportSingleReference } = useExportSingleReference();

  const referenceProjectName = m(
    displayReference?.privateReferenceProjectName,
    "",
  );

  return (
    <div className="relative">
      {needToMigrate(displayReference) ? (
        <Overlay
          reference={displayReference}
          parentProject={displayReference?.project?.parentProjects[0]}
        />
      ) : null}
      <div
        className={`border border-gray-100 rounded shadow ${
          needToMigrate(displayReference) ? "pointer-events-none" : ""
        }`}
        id={displayReference.id}
      >
        {displayReference?.projectId && !displayReference?.employeeId ? (
          <div className="relative group w-full mb-2 overflow-hidden rounded-t h-36">
            {picture?.url ? (
              <img
                src={picture?.url}
                alt="reference picture"
                className="absolute object-cover w-full h-full transition duration-300 ease-in-out cursor-pointer hover:scale-105"
                onClick={() => {
                  setFullImage(true);
                }}
              />
            ) : (
              <div className="absolute w-full h-full bg-gray-300"></div>
            )}
            {displayReference?.parentReferenceId === null ? (
              <MainReferenceIndicator />
            ) : null}
            <StarredReferenceIndicator
              isStarred={reference.isStarredForCurrentUser}
              referenceId={reference.id!}
            />
            <ImageModal
              picture={picture}
              pictureCredit={displayReference?.pictureCredit}
              open={fullImage}
              setFullImage={setFullImage}
            />
          </div>
        ) : null}
        <div className="p-4">
          <div className="flex flex-row justify-between items-start">
            <div>
              {displayReference.employeeId && !displayReference.projectId ? (
                <div className="my-0 text-sm font-medium text-black">
                  {referenceProjectName?.length > 0
                    ? referenceProjectName
                    : t(`searchHighlights:privateReference`)}
                </div>
              ) : !displayReference.employeeId && displayReference.projectId ? (
                m(displayReference?.title, t(`project:noTitle`))
              ) : (
                <Popup content={formattedProjectId}>
                  <Link
                    href={
                      displayReference.employeeId
                        ? `/project/${displayReference.projectId}/team#${displayReference.employeeId}`
                        : `/project/${displayReference.projectId}/references#${displayReference.id}`
                    }
                    className="block my-0 text-sm font-medium leading-tight text-black hover:text-blue-500"
                    data-cy="name"
                  >
                    {findAndReturnReferenceTitle(displayReference, t, m)}
                  </Link>
                </Popup>
              )}

              {role ? (
                <h3 className="text-sm leading-tight text-gray-800">{role}</h3>
              ) : null}
            </div>
            <div className="flex items-center gap-x-2">
              <CopyCurrentURL id={displayReference.id} />
              <DropdownButton
                options={
                  [
                    isReadyForReview
                      ? null
                      : {
                          text: t("common:edit"),
                          key: "edit",
                          icon: <FaEdit />,
                          onClick: () => {
                            setShowEditProjectModal(true);
                          },
                        },
                    displayReference?.projectId && !displayReference?.employeeId
                      ? {
                          text: t("project:addEmployeeReference"),
                          key: "addEmployeeReference",
                          icon: <FaBook />,
                          onClick: () => {
                            if (isEmployeePartOfTeam) {
                              setShowEditEmployeeReferenceModal(true);
                            } else {
                              setShowWarningModal(true);
                            }
                          },
                        }
                      : null,
                    { title: t("wordExport:referenceSheets") },
                    ...wordExportLanguageOptions.map((l) => {
                      return {
                        text: t(`languagesFull:${l.key}` as any),
                        value: l.value,
                        key: l.key,
                        icon: <FaDownload />,
                        onClick: () => {
                          if (displayReference?.id) {
                            exportSingleReference(displayReference.id, l.value);
                          }
                        },
                      };
                    }),
                  ].filter((o) => o) as any[]
                }
              ></DropdownButton>
              <EditReferenceModal
                id={displayReference.id}
                open={showEditProjectModal}
                close={() => {
                  setShowEditProjectModal(false);
                }}
              />
              <EditWarningModal
                open={showWarningModal}
                close={() => setShowWarningModal(false)}
                accept={() => {
                  setShowEditEmployeeReferenceModal(true);
                  setShowWarningModal(false);
                }}
              />
              <EditReferenceModal
                newEntity={{
                  projectId: displayReference.projectId,
                  employeeId: currentEmployee.id,
                  parentReferenceId: displayReference.id,
                }}
                close={() => setShowEditEmployeeReferenceModal(false)}
                open={showEditEmployeeReferenceModal}
              />
            </div>
          </div>

          <ReferencesContent
            isDraft={isDraft || isUnapprovedNew}
            reference={displayReference}
            isReadyForReview={isReadyForReview}
          />
        </div>
      </div>
    </div>
  );
};

const areDatesOrdered = (date1, date2) => {
  if (date1 && date2) return date1 <= date2;
  else return true;
};

export const isTimeRangeCoherent = (
  reference?: Partial<NewReference>,
  otherReference?: Partial<NewReference>,
): boolean => {
  if (!otherReference) return true;
  else if (!otherReference?.from && !otherReference?.to) return true;
  else {
    return (
      areDatesOrdered(otherReference?.from, reference?.from) &&
      areDatesOrdered(reference?.to, otherReference?.to)
    );
  }
};

export const arePhasesCoherent = (
  reference?: Partial<NewReference>,
  otherReference?: Partial<NewReference>,
): boolean => {
  if (!otherReference) return true;

  const primaryPhaseArray =
    reference?.primaryPhase === "sia" ? "siaPhases" : "hoaiPhases";

  if (
    reference?.primaryPhase !== otherReference?.primaryPhase &&
    (reference?.[primaryPhaseArray] ?? []).length > 0
  )
    return false;

  if (!otherReference[primaryPhaseArray]) return true;

  return (reference?.[primaryPhaseArray] ?? []).every((p) =>
    (otherReference?.[primaryPhaseArray] ?? []).includes(p),
  );
};

const ReferenceDataWarning = ({ reference }) => {
  const { t } = useTranslation(["common", "reference"]);
  return (
    <>
      {!(
        arePhasesCoherent(reference, reference?.parentReference) &&
        arePhasesCoherent(
          reference,
          reference?.parentReference?.parentReference,
        )
      ) ? (
        <div className="flex text-xs text-red-500">
          <FaExclamationTriangle />
          <div className="ml-1">{t(`reference:inconsistent_phases`)}</div>
        </div>
      ) : null}
      {!(
        isTimeRangeCoherent(reference, reference?.parentReference) &&
        isTimeRangeCoherent(
          reference,
          reference?.parentReference?.parentReference,
        )
      ) ? (
        <div className="flex text-xs text-red-500">
          <FaExclamationTriangle />
          <div className="ml-1">{t(`reference:inconsistent_duration`)}</div>
        </div>
      ) : null}
    </>
  );
};
