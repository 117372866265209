import React, { useContext, useState, useEffect, JSX } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "context/app";
import Creatable from "react-select/creatable";
import { customStyles, i18nOptions } from "./multiSelectField";
import { number } from "yup";
import { getTextFromMultiLang } from "helpers/multiLang";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { ListField } from "./ListField";
import { NumberField } from "./numberField";
import { ActionMeta } from "react-select";

type DiplomaType = {
  id?: string;
  year?: number;
  diplomaId: string | null;
  newText?: string;
};
type DiplomasType = DiplomaType[];

export type DiplomasFieldProps = GenericFieldProps<DiplomasType>;

const Diploma = ({ value, disabled, onChange, catalogue, language }) => {
  const { t } = useTranslation(["common", "form", "employee"]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validationSchema = number()
    .typeError(t("form:validation.NaN"))
    .min(1900, t("form:validation.tooLow", { value: "1900" }))
    .max(3000, t("form:validation.tooHigh", { value: "3000" }));

  const runValidation = () => {
    if (!disabled) {
      validationSchema
        .validate(value?.year)
        .then((_validatedValue) => {
          setErrorMessage(null);
        })
        .catch((e) => {
          setErrorMessage(
            e?.errors?.join(",") ?? t("form:validation.criticalError"),
          );
        });
    }
  };

  useEffect(() => {
    runValidation();
  }, []);

  const handleYearChange = (newValue) => {
    const newDiploma = { ...value, year: newValue };
    onChange(newDiploma);
  };

  const handleChange = (selectedOption: any, { action }: ActionMeta<any>) => {
    if (action === "create-option") {
      const newDiploma = {
        ...value,
        diplomaId: null,
        newText: selectedOption.value,
      };
      onChange(newDiploma);
    } else if (action === "select-option") {
      const newDiploma = { ...value, diplomaId: selectedOption.value };
      onChange(newDiploma);
    } else {
      // On clearing
      const newDiploma = { ...value, diplomaId: null, newText: undefined };
      onChange(newDiploma);
    }
  };

  const selectedDiploma = catalogue?.find((d) => d.id === value.diplomaId);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="w-24">
          <NumberField
            disabled={disabled}
            onChange={handleYearChange}
            placeholder={t("employee:diploma.year")}
            value={value?.year}
            language={language}
            isValid={errorMessage === null}
            onBlur={() => {
              runValidation();
            }}
          />
        </div>

        <div className="flex-1 ml-2 text-sm">
          <Creatable
            styles={customStyles}
            isDisabled={disabled}
            isClearable
            {...i18nOptions(t)}
            onChange={handleChange}
            options={catalogue}
            value={
              selectedDiploma ?? { value: value.newText, label: value.newText }
            }
          />
        </div>
      </div>
      {errorMessage ? (
        <div className="mt-2 text-xs text-red-500">{errorMessage}</div>
      ) : null}
    </>
  );
};

export const DiplomasField = ({
  value,
  onChange,
  language,
  disabled,
}: DiplomasFieldProps): JSX.Element => {
  const { catalogues } = useContext(AppContext);
  const m = getTextFromMultiLang(language ?? "de");

  const allDiplomas = catalogues?.diplomas?.map((diploma: any) => ({
    id: diploma.id,
    key: diploma.id,
    label: m(diploma.name),
    value: diploma.id,
  }));

  const newDiploma = { id: undefined, diplomaId: null, key: "new" };

  return (
    <ListField
      value={(value ?? []).map((item) => ({
        ...item,
        key: `${item.id}-${item.diplomaId}`,
      }))}
      language={language}
      onChange={onChange}
      disabled={disabled}
      render={Diploma}
      newItem={newDiploma}
      catalogue={allDiplomas}
    />
  );
};

export const diplomasField: GenericField<DiplomasType> = {
  renderer: DiplomasField,
  onBeforeSave: (value) =>
    value
      ?.map((v) => ({
        year: Number(v.year),
        diplomaId: v.diplomaId,
        newText: v.newText,
      }))
      .filter(
        (v) =>
          (v.diplomaId ? +v.diplomaId : 0) > 0 || (v.newText?.length ?? 0) > 0,
      ),
  equals: (a, b) => {
    return (
      (a
        ?.map(
          (v) =>
            `${(v.year ?? 0 > 0) ? v.year : ""}-${v.diplomaId}-${
              v.newText ?? ""
            }`,
        )
        .join(",") ?? "") ===
      (b
        ?.map(
          (v) =>
            `${(v.year ?? 0 > 0) ? v.year : ""}-${v.diplomaId}-${
              v.newText ?? ""
            }`,
        )
        .join(",") ?? "")
    );
  },
};
