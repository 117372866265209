import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import {
  FaBook,
  FaBuilding,
  FaUserAlt,
  FaEdit,
  FaSpinner,
  FaTrashAlt,
} from "react-icons/fa";
import { EMPLOYEE_NOT_READY_FOR_REVIEW_DRAFTS } from "queries/newDraft";
import { Client, Employee, NewProject, NewReference } from "generated/graphql";
import { Avatar } from "../avatar";
import { ActionLink } from "components/button";
import { LoadingSpinner } from "components/loadingSpinner";
import { useMultiLang } from "helpers/multiLang";
import { getShortProjectId } from "pages/newProject/header";
import { useTranslation } from "react-i18next";
import {
  getChangeType,
  Changer,
  ConfirmationModal,
} from "pages/manageData/drafts/drafts";
import { IconButton } from "components/button";

import {
  REJECT_PROJECT_DRAFT_MUTATION,
  REJECT_CLIENT_DRAFT_MUTATION,
  REJECT_EMPLOYEE_DRAFT_MUTATION,
  REJECT_REFERENCE_DRAFT_MUTATION,
} from "queries/newDraft";

// HELPER components

const ModifierNote = ({ draft }: { draft: any }) => {
  const changeType = getChangeType(draft);
  const { t } = useTranslation(["common", "drafts"]);

  return (
    <div className="flex items-center justify-end mt-4 space-x-2 text-xs text-gray-600">
      <FaEdit />
      <div>
        {changeType === "new" && t("drafts:entryCreated")}
        {changeType === "removed" && t("drafts:entryDeleted")}
        {changeType === "edited" && t("drafts:entryModified")} {t("by")}{" "}
        <Changer changer={draft.modifiedBy} /> {t("drafts:on")}{" "}
        {dayjs(`${draft.updatedAt}Z`).format("DD.MM.YYYY")},{" "}
        {t("common:created")} {t("drafts:on")}{" "}
        {dayjs(`${draft.insertedAt}Z`).format("DD.MM.YYYY")}
      </div>
    </div>
  );
};

const RejectionComponent = ({
  draft,
  rejectMutation,
}: {
  draft: any;
  rejectMutation: any;
}) => {
  const [performingRejectAction, setPerformingRejectAction] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation("drafts");

  const [rejectMut] = useMutation(rejectMutation);
  const rejectDraft = (draftId: number) => {
    return rejectMut({
      variables: { id: draftId },
      refetchQueries: ["OwnStillEditingDraftsQuery"],
    });
  };

  return (
    <>
      <IconButton
        type="warning"
        Icon={
          performingRejectAction
            ? () => <FaSpinner className="animate-spin" />
            : FaTrashAlt
        }
        onClick={() => {
          setShowConfirmation(true);
        }}
      >
        {t("reject")}
      </IconButton>
      {showConfirmation ? (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          confirmationText={t("confirmReject")}
          type="reject"
          confirmButtonText={t("reject")}
          applyActionCallback={() => {
            if (draft.id) {
              setPerformingRejectAction(true);
              rejectDraft(draft.id).catch(() => {
                setPerformingRejectAction(false);
              });
              // No need to reset `performingRejectAction` to true:
              // After the mutation all drafts are refetched and this draft is removed from the dom
              // Any attempt to modify the component after the mutation will result in an error
              // since the component do not exists anymore
            }
          }}
        />
      ) : null}
    </>
  );
};

const ProjectOpenDraft = ({ draft }: { draft: NewProject }) => {
  const approved = draft.approved as NewProject;
  const m = useMultiLang();
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div>
          <div className="inline-block px-2 mr-3 mb-2 ml-0 text-xs text-blue-500 whitespace-nowrap bg-blue-500 bg-opacity-10 rounded border-none sm:text-sm">
            {getShortProjectId(approved.abacusProjectId ?? "")}
          </div>
          <div>{m(approved.name)}</div>
        </div>
        <div>
          <div className="flex justify-end items-center gap-x-2">
            <ActionLink
              link={`/project/${approved.id}`}
              label={"Show Project"}
              Icon={FaBook}
            />
            <RejectionComponent
              draft={draft}
              rejectMutation={REJECT_PROJECT_DRAFT_MUTATION}
            />
          </div>
          <ModifierNote draft={draft} />
        </div>
      </div>
    </div>
  );
};

const ReferenceOpenDrafts = ({ draft }: { draft: NewReference }) => {
  const approved = draft.approved as NewReference;
  // Reference can be a draft on an existing reference, or a new reference
  const reference = approved ?? draft;
  const m = useMultiLang();
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div>
          {reference.projectId ? (
            <>
              <div className="inline-block px-2 mr-3 mb-2 ml-0 text-xs text-blue-500 whitespace-nowrap bg-blue-500 bg-opacity-10 rounded border-none sm:text-sm">
                {getShortProjectId(reference.project?.abacusProjectId ?? "")}
              </div>
              <div>{m(reference.project?.name)}</div>
            </>
          ) : (
            <div>{reference.employee?.fullName}</div>
          )}
        </div>
        <div>
          <div className="flex justify-end items-center gap-x-2">
            <ActionLink
              link={`/employee/${reference.employeeId}/references#${draft.id}`}
              label={"Show Reference"}
              Icon={FaBook}
            />
            <RejectionComponent
              draft={draft}
              rejectMutation={REJECT_REFERENCE_DRAFT_MUTATION}
            />
          </div>
          <ModifierNote draft={draft} />
        </div>
      </div>
    </div>
  );
};

const EmployeeOpenDraft = ({ draft }: { draft: Employee }) => {
  const approved = draft.approved as Employee;
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center gap-x-4">
          <Avatar
            size="small"
            url={approved.colorProfilePicture ?? approved.profilePicture}
          />
          <div className="text-xl">{approved.fullName}</div>
        </div>
        <div>
          <div className="flex justify-end items-center gap-x-2">
            <ActionLink
              link={`/employee/${approved.id}`}
              label={"Show Profile"}
              Icon={FaUserAlt}
            />
            <RejectionComponent
              draft={draft}
              rejectMutation={REJECT_EMPLOYEE_DRAFT_MUTATION}
            />
          </div>
          <ModifierNote draft={draft} />
        </div>
      </div>
    </div>
  );
};

const ClientOpenDrafts = ({ draft }: { draft: Client }) => {
  const approved = draft.approved as Client;
  return (
    <div className="p-4 bg-white border rounded border-gray-300 shadow">
      <div className="w-full flex justify-between items-center">
        <div className="text-xl">{approved.name}</div>
        <div>
          <div className="flex justify-end items-center gap-x-2">
            <ActionLink
              link={`/employee/${approved.id}`}
              label={"Show Client"}
              Icon={FaBuilding}
            />
            <RejectionComponent
              draft={draft}
              rejectMutation={REJECT_CLIENT_DRAFT_MUTATION}
            />
          </div>
          <ModifierNote draft={draft} />
        </div>
      </div>
    </div>
  );
};

// MAIN COMPONENT

type EmployeeOpenDraftsProps = {
  employeeId: string;
};

export const EmployeeOpenDrafts: React.FC<EmployeeOpenDraftsProps> = ({
  employeeId,
}) => {
  const { data, loading } = useQuery(EMPLOYEE_NOT_READY_FOR_REVIEW_DRAFTS, {
    variables: { id: +employeeId },
    fetchPolicy: "no-cache",
  });

  const { t } = useTranslation("employee");

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="my-8 flex flex-col space-y-8">
      <p className="text-gray-600 italic">{t("openDraftsDesc")}</p>
      {data?.projectDrafts?.length == 0 &&
      data?.employeeDrafts?.length == 0 &&
      data?.clientDrafts?.length == 0 &&
      data?.referenceDrafts?.length == 0 ? (
        <div className="text-lg text-gray-500">{t("noOpenDrafts")}</div>
      ) : null}
      {data?.projectDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("projectDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.projectDrafts?.map((draft: NewProject) => {
              return <ProjectOpenDraft key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
      {data?.employeeDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("employeeDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.employeeDrafts?.map((draft: Employee) => {
              return <EmployeeOpenDraft key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
      {data?.clientDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("clientDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.clientDrafts?.map((draft: Client) => {
              return <ClientOpenDrafts key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
      {data?.referenceDrafts?.length > 0 ? (
        <div>
          <h2 className="text-xl font-medium text-blue-500">
            {t("referenceDrafts")}
          </h2>
          <div className="mt-2 flex flex-col gap-y-4">
            {data?.referenceDrafts?.map((draft: NewReference) => {
              return <ReferenceOpenDrafts key={draft.id} draft={draft} />;
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};
