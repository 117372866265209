import React, { useEffect, useState, JSX } from "react";
import { useLocation, useSearch as useQuery } from "wouter";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { useDebounce } from "@uidotdev/usehooks";
import Layout from "layout/layout";
import useInfiniteScroll from "helpers/useInfiniteScroll";
import { niceReadableNumber, ResultItem } from "./components/resultItem";
import {
  initialState,
  SearchFilter,
  SelectedFilterButtons,
  toElasticParams,
} from "./components/filter";
import { SearchBox } from "./components/searchBox";
import { SearchResults, useSearch } from "./helpers";
import { LoadingSpinner } from "components/loadingSpinner";
import { TabButton } from "components/button";
import { AiOutlineWarning } from "react-icons/ai";

// HELPER COMPONENTS

const decodeSearchState = (
  url: string,
): { searchText: string; filters: SelectedFilterButtons } => {
  const params = new URLSearchParams(url);
  const searchText = params.get("q") || "";
  const filters = JSON.parse(decodeURIComponent(params.get("filters") || "{}"));
  return { filters: { ...initialState, ...filters }, searchText };
};

export const encodeSearchState = (
  searchText: string,
  filters: SelectedFilterButtons,
): string => {
  // Don't encode selected projects filter, since it relies on in memory state (e.g. the mapping of id <> title)
  const { selectedProjects, ...otherFilters } = filters;
  const nonEmptyFilters = R.filter(
    (v: any) => v && !R.isEmpty(v),
    otherFilters,
  );
  if (R.isEmpty(nonEmptyFilters)) {
    return `q=${searchText}`;
  }
  return `q=${searchText}&filters=${encodeURIComponent(
    JSON.stringify(nonEmptyFilters),
  )}`;
};

// MAIN COMPONENT

export const ResultsPage = (): JSX.Element => {
  const [location, navigate] = useLocation();
  const query = useQuery();

  const urlState = decodeSearchState(query ?? "");
  const searchText = useDebounce(urlState.searchText, 200);
  const { t } = useTranslation("search");

  const [filters, setFilters] = useState<SelectedFilterButtons>(
    urlState.filters,
  );

  const [selectedFastResultsTab, setSelectedFastResultsTab] =
    useState<boolean>(true);
  // TODO: get rid of this duplication
  const [fastPageNumber, setFastPageNumber] = useState(1);
  const [slowPageNumber, setSlowPageNumber] = useState(1);
  const [fastResults, fastSearch] = useSearch(
    toElasticParams(searchText, "fast", filters),
  );
  const [slowResults, slowSearch] = useSearch(
    toElasticParams(searchText, "slow", filters),
  );

  const resetFastInfiniteScroll = useInfiniteScroll(() => {
    if (selectedFastResultsTab) {
      setFastPageNumber(fastPageNumber + 1);
    }
  });
  const resetSlowInfiniteScroll = useInfiniteScroll(() => {
    if (!selectedFastResultsTab) {
      setSlowPageNumber(slowPageNumber + 1);
    }
  });

  useEffect(() => {
    // if searchText or filter or scope changed, search + reset pageNumber
    if (searchText !== "") {
      // store the filters in the url
      const newLocation = `${location}?${encodeSearchState(
        searchText,
        filters,
      )}`;

      navigate(newLocation, { replace: true });
      setFastPageNumber(1);
      setSlowPageNumber(1);
      const cancelFastRequest = fastSearch(1);
      const cancelSlowRequest = slowSearch(1);
      return () => {
        cancelFastRequest();
        cancelSlowRequest();
      };
    }
  }, [searchText, filters]);

  useEffect(() => {
    // only search from pageNumber change if not on page 1
    if (fastPageNumber !== 1 && searchText !== "") {
      return fastSearch(fastPageNumber);
    }
  }, [fastPageNumber]);
  useEffect(() => {
    // only search from pageNumber change if not on page 1
    if (slowPageNumber !== 1 && searchText !== "") {
      return slowSearch(slowPageNumber);
    }
  }, [slowPageNumber]);

  useEffect(() => {
    if (fastResults.resultsCount > fastResults.results.length) {
      resetFastInfiniteScroll();
    }
  }, [fastResults.results]);
  useEffect(() => {
    if (slowResults.resultsCount > slowResults.results.length) {
      resetSlowInfiniteScroll();
    }
  }, [slowResults.results]);

  return (
    <Layout noPadding>
      <div className="py-5 shadow">
        <div className="flex flex-row px-3 mx-auto gap-x-8 max-w-screen-tbf">
          <SearchBox />
        </div>
        <SearchFilter filters={filters} onChangeFilters={setFilters} />
        <div className="flex flex-row px-3 mx-auto gap-x-8 max-w-screen-tbf">
          <ResultsTabHeader
            text="fast"
            results={fastResults}
            showSpinnner={fastPageNumber === 1 && !fastResults?.error}
            onClick={() => {
              setSelectedFastResultsTab(true);
              resetFastInfiniteScroll();
            }}
            primary={selectedFastResultsTab}
          />
          <ResultsTabHeader
            text="slow"
            results={slowResults}
            showSpinnner={slowPageNumber === 1 && !slowResults?.error}
            onClick={() => {
              setSelectedFastResultsTab(false);
              resetSlowInfiniteScroll();
            }}
            primary={!selectedFastResultsTab}
          />
        </div>
      </div>

      <div className="relative pt-5 mx-auto max-w-screen-tbf">
        {searchText === "" ? (
          <h2 className="ml-3 text-red-500">{t("enter")}</h2>
        ) : (
          <div className="ml-3">
            {selectedFastResultsTab ? (
              <ResultsTab
                results={fastResults}
                pageNumber={fastPageNumber}
                filters={filters}
                searchText={searchText}
              />
            ) : (
              <ResultsTab
                results={slowResults}
                pageNumber={slowPageNumber}
                filters={filters}
                searchText={searchText}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

const ResultsTabHeader = ({
  results,
  text,
  showSpinnner,
  onClick,
  primary = false,
}: {
  results: SearchResults;
  text: string;
  showSpinnner: boolean;
  onClick: () => void;
  primary: boolean;
}) => {
  const { t } = useTranslation("search");

  return (
    <div>
      <TabButton onClick={onClick} primary={primary}>
        <div className="flex flex-row">
          {t(text as any)}
          {" ("}
          {results.isLoading && showSpinnner ? (
            <div className="w-8 h-8">
              <LoadingSpinner />
            </div>
          ) : (
            niceReadableNumber(results.resultsCount)
          )}
          {")"}
        </div>
      </TabButton>
    </div>
  );
};

type ResultsTabProps = {
  searchText: string;
  filters: SelectedFilterButtons;
  pageNumber: number;
  results: SearchResults;
};

const ResultsTab = ({
  filters,
  pageNumber,
  results: { error, resultsCount, results, isLoading },
}: ResultsTabProps) => {
  const { t } = useTranslation(["search"]);

  const isImageSubFilterSelected =
    filters.selectedSubFilters?.file?.find((item) => item === "Image") &&
    filters.selectedSubFilters?.file?.length === 1;

  const isImageFilterSelected =
    isImageSubFilterSelected || filters.selectedTopFilter === "image";

  return (
    <>
      {error ? (
        <div className="grid items-center w-full h-auto grid-cols-12 py-4 px-2 text-gray-800 border-gray-500 border rounded">
          <div className="col-span-1 mr-3 pl-5">
            <AiOutlineWarning size="30" className="text-gray-500" />
          </div>
          <div className="col-span-11">
            <h1 className="text-xl">{t("search:timeoutError")}:</h1>
            <div className="text-base">{t("search:timeoutMessage")}</div>
          </div>
        </div>
      ) : null}
      <div>
        <div
          className={classnames("", {
            "grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4":
              isImageFilterSelected,
          })}
        >
          {isLoading && !error ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : null}
          {!isLoading && !error && results && !isImageFilterSelected ? (
            <div className="px-2 flex items-center my-4 gap-x-4">
              <AiOutlineWarning size="30" className="text-gray-500" />
              <div className="text-gray-500">
                {t("search:postsWarning")}
                <a
                  target="_blank"
                  href="https://next.tbf.digital/posts"
                  rel="noreferrer"
                >
                  next.tbf.digital
                </a>
              </div>
            </div>
          ) : null}
          {results &&
            results.map((r: any) => (
              <ResultItem
                key={r.type + r.id}
                result={r}
                isImageFilterSelected={isImageFilterSelected}
              />
            ))}
        </div>
        <div style={{ minHeight: 60 }}>
          {isLoading && pageNumber !== 1 ? <LoadingSpinner /> : null}
          {resultsCount > 5 && resultsCount <= results.length
            ? t("search:noMoreResults")
            : null}
          {!isLoading && resultsCount === 0 && results.length === 0 ? (
            <span className="px-2">{t("search:noResults")}</span>
          ) : null}
        </div>
      </div>
    </>
  );
};
