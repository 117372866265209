import React, { useEffect, JSX } from "react";
import { Link, Redirect, Route, Switch, useLocation } from "wouter";
import { TabButton } from "./button";
import { IconType } from "react-icons";

type NoUrlPane = {
  name: string;
  key: string;
  Icon?: IconType;
  annotation?: string;
  path?: string;
};

export type Pane = {
  name: string;
  key: string;
  Icon?: IconType;
  annotation?: string;
  Content: () => JSX.Element;
  path?: string;
};

export type TabProps = {
  panes: Pane[];
  path: string;
  currentTab?: string;
  onChange?: (key: string) => void;
};

export const useTab = (tabProps: TabProps): void => {
  const { panes, path, currentTab } = tabProps;
  const [_location, navigate] = useLocation();

  useEffect(() => {
    if (!currentTab && panes?.length > 0) {
      // Redirect to path with tab name. This way switching tab we always change last parameter
      navigate(`${path}/${panes[0].key}`, { replace: true });
    }
  }, [currentTab, panes]);
};

export const TabBar = ({
  panes,
  path,
  currentTab,
  onChange,
}: TabProps): JSX.Element => {
  const selectedTab = currentTab ?? "";

  useEffect(() => {
    if (onChange) {
      onChange(selectedTab);
    }
  }, [selectedTab]);

  return (
    <div className="flex space-x-8 text-md">
      {panes.map((l) => {
        const { name, Icon } = l;
        return (
          <Link key={l.key} href={l.path ? l.path : `${path}/${l.key}`}>
            <TabButton
              key={l.name}
              primary={selectedTab === l.key}
              className="items-center"
            >
              {Icon && <Icon className="inline-block mr-2" />} {name}
              {l.annotation && (
                <span className="mb-2 ml-1 text-xs text-red-500">
                  {l.annotation}
                </span>
              )}
            </TabButton>
          </Link>
        );
      })}
    </div>
  );
};

export const NoUrlTabBar = ({
  panes,
  selectedTab,
  onChange,
}: {
  panes: NoUrlPane[];
  selectedTab: string;
  onChange: (tab: string) => void;
}): JSX.Element => {
  return (
    <div className="flex space-x-8 text-md">
      {panes.map((l) => {
        const { name, Icon } = l;
        return (
          <TabButton
            key={l.name}
            primary={selectedTab === l.key}
            className="items-center"
            onClick={() => onChange(l.key)}
          >
            {Icon && <Icon className="inline-block mr-2" />} {name}
            {l.annotation && (
              <span className="mb-2 ml-1 text-xs text-red-500">
                {l.annotation}
              </span>
            )}
          </TabButton>
        );
      })}
    </div>
  );
};

export const TabContent = ({ panes, path }: TabProps): JSX.Element => {
  const firstPaneKey = panes[0]?.key ?? "";

  return (
    <Switch>
      <Route path={`/${path}/`}>
        <Redirect to={`/${path}/${firstPaneKey}`} />
      </Route>
      {panes.map((p) => {
        const { Content } = p;
        return (
          <Route key={p.key} path={p.path ? p.path : `${path}/${p.key}`}>
            <Content />
          </Route>
        );
      })}
    </Switch>
  );
};
