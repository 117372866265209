import React, { Dispatch, SetStateAction, useState, JSX } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/button";
import { FaExclamationTriangle, FaFileWord, FaSpinner } from "react-icons/fa";
import { Modal } from "components/modal";
import { IoMdClose } from "react-icons/io";
import { MdSave } from "react-icons/md";
import Select from "react-select";
import { customStyles } from "components/form/multiSelectField";
import {
  HiOutlineMenu,
  HiOutlineSortDescending,
  HiViewGrid,
  HiViewGridAdd,
} from "react-icons/hi";
import { LoadingSpinner } from "components/loadingSpinner";
import { Employee } from "generated/graphql";
import { getTextFromMultiLang } from "helpers/multiLang";
import {
  getReferenceDuration,
  selectReferenceData,
} from "helpers/getReferenceData";
import {
  arePhasesCoherent,
  isTimeRangeCoherent,
} from "components/newReferences";
import { Popup } from "components/popup";
import { getShortProjectId } from "pages/newProject/header";
import { wordExportLanguageOptions } from "helpers/languages";
import { LargeSelectionWrapper } from "components/largeSelectionLayout/largeSelectionWrapper";
import { LargeSelectableButton } from "components/largeSelectionLayout/largeSelectableButton";
import "./styles.scss";
import { TriStateCheckbox } from "components/triStateCheckbox";
import {
  sortReferencesChronologically,
  useExportCv,
} from "pages/newProject/wordExport";
import { findAndReturnReferenceTitle } from "helpers/findAndReturnReferenceTitle";

const CvExportWizard: React.FC<{
  approvedEmployee: Partial<Employee>;
}> = ({ approvedEmployee }) => {
  const { t } = useTranslation(["employee", "tendering"]);
  const { exportCv, loading } = useExportCv();

  const [showExportWizard, setShowExportWizard] = useState(false);

  return (
    <>
      <IconButton
        Icon={
          loading ? () => <FaSpinner className="animate-spin" /> : FaFileWord
        }
        onClick={() => setShowExportWizard(true)}
      >
        {t("tendering:curriculumVitae")}
      </IconButton>
      {showExportWizard ? (
        <CvExportModal
          approvedEmployee={approvedEmployee}
          loading={loading}
          exportCv={exportCv}
          showExportWizard={showExportWizard}
          setShowExportWizard={setShowExportWizard}
        />
      ) : null}
    </>
  );
};

export const CvExportModal = ({
  approvedEmployee,
  loading,
  exportCv,
  showExportWizard,
  setShowExportWizard,
}: {
  approvedEmployee: Partial<Employee>;
  loading: boolean;
  exportCv: (
    employeeId: string,
    language: string,
    inlineCVItems: boolean,
    referenceIdsToExport?: Set<string> | undefined,
  ) => void;
  showExportWizard: boolean;
  setShowExportWizard: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation(["employee", "languagesFull", "common"]);

  const [inputValue, setInputValue] = useState("de");
  const [checkedAll, setCheckedAll] = useState(false);

  const [selectedLayout, setSelectedLayout] = useState(0);
  const [selectedReferenceOption, setselectedReferenceOption] = useState(0);
  const [referenceIdsToExport, setReferenceIdsToExport] = useState(
    new Set<string>([]),
  );

  const languageOptions = wordExportLanguageOptions.map((languageOption) => ({
    ...languageOption,
    label: t(`languagesFull:${languageOption.key}` as any),
  }));
  const selectedOption = languageOptions?.find((c) => c.key === inputValue);

  const sortedReferences = sortReferencesChronologically(
    approvedEmployee.references ?? [],
  );

  return (
    <Modal
      size="normal"
      title={
        <div className="flex flex-row p-8 text-2xl font-medium">
          <div className="mr-3 text-gray-400">{t("employee:exportCV")}:</div>
          <div className="text-red-500">{approvedEmployee.fullName}</div>
        </div>
      }
      open={showExportWizard}
      close={() => setShowExportWizard(false)}
      actions={
        <div className="flex flex-row px-6 space-x-2 grow">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div
              className={`flex flex-row grow ${
                selectedReferenceOption == 1
                  ? "justify-between pl-4"
                  : "justify-end"
              }`}
            >
              {selectedReferenceOption == 1 && (
                <div className="flex items-center space-x-3">
                  <TriStateCheckbox
                    indeterminate={
                      referenceIdsToExport.size < sortedReferences.length &&
                      referenceIdsToExport.size != 0
                    }
                    checked={
                      referenceIdsToExport.size == sortedReferences.length
                    }
                    onChange={() => {
                      setCheckedAll(!checkedAll);
                      if (checkedAll == false) {
                        setReferenceIdsToExport(
                          new Set(sortedReferences?.map((t) => t.id)),
                        );
                      } else {
                        setReferenceIdsToExport(new Set());
                      }
                    }}
                  />
                  <div> {t("employee:selectAll")}</div>
                </div>
              )}
              <div className="flex flex-row justify-end space-x-2">
                <IconButton
                  type="secondary"
                  Icon={IoMdClose}
                  onClick={() => setShowExportWizard(false)}
                >
                  {t("common:cancel")}
                </IconButton>
                <IconButton
                  Icon={MdSave}
                  onClick={() => {
                    setShowExportWizard(false);
                    exportCv(
                      approvedEmployee.id ?? "",
                      inputValue,
                      selectedLayout == 0,
                      selectedReferenceOption == 0
                        ? new Set(
                            sortedReferences?.map((reference) => reference.id),
                          )
                        : referenceIdsToExport,
                    );
                  }}
                >
                  {t("employee:exportCV")}
                </IconButton>
              </div>
            </div>
          )}
        </div>
      }
    >
      <div className="flex flex-row px-8 m-4">
        <div className="flex-1">
          <div className="font-medium whitespace-pre-line">
            {t("employee:chooseLanguageTitle")}
          </div>
          <div className="text-xs text-gray-500 whitespace-pre-line">
            {t("employee:chooseLanguageSubtitle")}
          </div>
        </div>
        <div className="w-1/2 space-y-1">
          <Select
            onChange={(value) => {
              setInputValue(value?.key ?? "");
            }}
            styles={customStyles}
            isDisabled={false}
            options={languageOptions}
            value={selectedOption}
          />
          <div className="text-xs text-gray-500">
            {inputValue == "de" || inputValue == "de-DE"
              ? t("employee:exportPhase", {
                  phase:
                    inputValue == "de"
                      ? t("employee:siaPhases")
                      : t("employee:hoaiPhases"),
                })
              : null}
          </div>
        </div>
      </div>
      <hr />
      <LargeSelectionWrapper
        title={t("employee:chooseLayoutTitle")}
        subtitle={t("employee:chooseLayoutSubtitle")}
      >
        <LargeSelectableButton
          isSelected={selectedLayout == 0}
          onClick={() => setSelectedLayout(0)}
          Icon={HiOutlineMenu}
          title={t("employee:chooseFirstLayoutTitle")}
          subtitle={t("employee:chooseFirstLayoutSubtitle")}
        />
        <LargeSelectableButton
          isSelected={selectedLayout == 1}
          onClick={() => setSelectedLayout(1)}
          Icon={HiOutlineSortDescending}
          title={t("employee:chooseSecondLayoutTitle")}
          subtitle={t("employee:chooseSecondLayoutSubtitle")}
        />
      </LargeSelectionWrapper>
      <hr />
      <LargeSelectionWrapper
        title={t("employee:chooseReferenceTitle")}
        subtitle={t("employee:chooseReferenceSubtitle")}
      >
        <LargeSelectableButton
          isSelected={selectedReferenceOption == 0}
          onClick={() => {
            setselectedReferenceOption(0);
            setReferenceIdsToExport(new Set());
          }}
          Icon={HiViewGrid}
          title={t("employee:chooseFirstReferenceTitle")}
          subtitle={t("employee:chooseFirstReferenceSubtitle")}
        />
        <LargeSelectableButton
          isSelected={selectedReferenceOption == 1}
          onClick={() => setselectedReferenceOption(1)}
          Icon={HiViewGridAdd}
          title={t("employee:chooseSecondReferenceTitle")}
          subtitle={t("employee:chooseSecondReferenceSubtitle")}
        />
      </LargeSelectionWrapper>
      {selectedReferenceOption == 1 && (
        <div className="px-8 m-4">
          {sortedReferences?.map((reference) => {
            const from = selectReferenceData(reference, "from");
            const to = selectReferenceData(reference, "to");
            const duration = getReferenceDuration(to, from);

            return (
              <ReferenceCheckbox
                reference={reference}
                referenceIdsToExport={referenceIdsToExport}
                setReferenceIdsToExport={setReferenceIdsToExport}
                duration={duration}
                key={reference.id}
              />
            );
          })}
        </div>
      )}
    </Modal>
  );
};

const ReferenceCheckbox = ({
  reference,
  referenceIdsToExport,
  setReferenceIdsToExport,
  duration,
}): JSX.Element => {
  const { t } = useTranslation(["employee", "project", "abacusProjects"]);
  const m = getTextFromMultiLang("de");
  const title = findAndReturnReferenceTitle(reference, t, m);

  return (
    <div
      key={reference?.id}
      className="grid items-center grid-cols-8 gap-4 pl-2"
    >
      <div className="flex flex-row items-center col-span-1 space-x-3">
        <input
          className="mr-1 mb-0.5 relative inline-block transition accent-blue-500 cursor-pointer"
          type="checkbox"
          checked={referenceIdsToExport.has(reference.id)}
          onChange={() => {
            if (referenceIdsToExport.has(reference.id)) {
              setReferenceIdsToExport(
                (references) =>
                  new Set([...references].filter((id) => id != reference.id)),
              );
            } else {
              setReferenceIdsToExport((references) =>
                new Set(references).add(reference.id),
              );
            }
          }}
        />
        <div>
          {reference?.project?.abacusProjectId
            ? getShortProjectId(reference?.project?.abacusProjectId)
            : t(`project:notAvailable`)}
        </div>
      </div>
      <div className="col-span-3 truncate">{title}</div>
      <div className="text-gray-500">
        {duration.length > 0 ? duration : t(`project:notAvailable`)}
      </div>
      <div className="flex flex-row items-center col-span-3 text-gray-500 truncate space-x-14">
        <div className="flex">
          {m(
            reference?.roles?.[0]?.referenceRole?.name,
            t("abacusProjects:role_0"),
          )}
          <div className="flex items-center px-2">
            <InconsistentDataWarning reference={reference} />
          </div>
        </div>
      </div>
    </div>
  );
};

const InconsistentDataWarning = ({ reference }): JSX.Element => {
  const { t } = useTranslation(["common", "reference"]);
  return (
    <div className="flex space-x-2 text-xs text-yellow-600">
      {!(
        arePhasesCoherent(reference, reference?.parentReference) &&
        arePhasesCoherent(
          reference,
          reference?.parentReference?.parentReference,
        )
      ) ? (
        <Popup content={t(`reference:inconsistent_phases`)}>
          <FaExclamationTriangle />
        </Popup>
      ) : null}
      {!(
        isTimeRangeCoherent(reference, reference?.parentReference) &&
        isTimeRangeCoherent(
          reference,
          reference?.parentReference?.parentReference,
        )
      ) ? (
        <Popup content={t(`reference:inconsistent_duration`)}>
          <FaExclamationTriangle />
        </Popup>
      ) : null}
    </div>
  );
};

export default CvExportWizard;
