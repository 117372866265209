import React, { JSX } from "react";
import { useQuery, useMutation, ApolloCache } from "@apollo/client";

import {
  READY_FOR_REVIEW_DRAFTS,
  APPROVE_PROJECT_DRAFT_MUTATION,
  REJECT_PROJECT_DRAFT_MUTATION,
  RESET_PROJECT_DRAFT_MUTATION,
  APPROVE_CLIENT_DRAFT_MUTATION,
  REJECT_CLIENT_DRAFT_MUTATION,
  RESET_CLIENT_DRAFT_MUTATION,
  APPROVE_EMPLOYEE_DRAFT_MUTATION,
  REJECT_EMPLOYEE_DRAFT_MUTATION,
  RESET_EMPLOYEE_DRAFT_MUTATION,
  APPROVE_REFERENCE_DRAFT_MUTATION,
  REJECT_REFERENCE_DRAFT_MUTATION,
  RESET_REFERENCE_DRAFT_MUTATION,
  APPROVE_TEAM_MEMBER_DRAFT_MUTATION,
  REJECT_TEAM_MEMBER_DRAFT_MUTATION,
  RESET_TEAM_MEMBER_DRAFT_MUTATION,
} from "queries/newDraft";
import Drafts from "./drafts";

const updateFunction =
  (_listName: string, _id: string) =>
  (_cache: ApolloCache<any>, _data: any) => {
    // TODO: Activate this later as need more testing
    // cache.modify({
    //   fields: {
    //     [listName]: (existingDrafts = []) => {
    //       return [...existingDrafts.filter(d => d.id === `${id}`)];
    //     },
    //   },
    // });
  };

const DraftsShell = ({
  countNotReadyForReviewDrafts,
}: {
  countNotReadyForReviewDrafts: number;
}): JSX.Element => {
  const { data, loading, error } = useQuery(READY_FOR_REVIEW_DRAFTS, {
    fetchPolicy: "no-cache",
  });

  const [approveProject] = useMutation(APPROVE_PROJECT_DRAFT_MUTATION);
  const [rejectProject] = useMutation(REJECT_PROJECT_DRAFT_MUTATION);
  const [resetProject] = useMutation(RESET_PROJECT_DRAFT_MUTATION);
  const [approveClient] = useMutation(APPROVE_CLIENT_DRAFT_MUTATION);
  const [rejectClient] = useMutation(REJECT_CLIENT_DRAFT_MUTATION);
  const [resetClient] = useMutation(RESET_CLIENT_DRAFT_MUTATION);
  const [approveEmployee] = useMutation(APPROVE_EMPLOYEE_DRAFT_MUTATION);
  const [rejectEmployee] = useMutation(REJECT_EMPLOYEE_DRAFT_MUTATION);
  const [resetEmployee] = useMutation(RESET_EMPLOYEE_DRAFT_MUTATION);
  const [approveTeamMember] = useMutation(APPROVE_TEAM_MEMBER_DRAFT_MUTATION);
  const [rejectTeamMember] = useMutation(REJECT_TEAM_MEMBER_DRAFT_MUTATION);
  const [resetTeamMember] = useMutation(RESET_TEAM_MEMBER_DRAFT_MUTATION);
  const [approveReference] = useMutation(APPROVE_REFERENCE_DRAFT_MUTATION);
  const [rejectReference] = useMutation(REJECT_REFERENCE_DRAFT_MUTATION);
  const [resetReference] = useMutation(RESET_REFERENCE_DRAFT_MUTATION);

  if (error) {
    throw new Error("Error while loading drafts: " + JSON.stringify(error));
  }

  const refetchQueries = ["DraftsQuery", "CountDraftsAndTranslationsQuery"];

  return (
    <Drafts
      loading={loading}
      countNotReadyForReviewDrafts={countNotReadyForReviewDrafts}
      projectDrafts={data?.projectDrafts ?? []}
      employeeDrafts={data?.employeeDrafts ?? []}
      clientDrafts={data?.clientDrafts ?? []}
      referenceDrafts={data?.referenceDrafts ?? []}
      projectTeamMemberDrafts={data?.projectTeamMemberDrafts ?? []}
      approveProjectDraft={(id: string) => {
        return approveProject({
          variables: { id },
          refetchQueries,
          update: updateFunction("newProjectDrafts", id),
        });
      }}
      rejectProjectDraft={(id: string) => {
        return rejectProject({
          variables: { id },
          refetchQueries,
          update: updateFunction("newProjectDrafts", id),
        });
      }}
      resetProjectDraft={(id: string) => {
        return resetProject({
          variables: { id },
          refetchQueries,
          update: updateFunction("newProjectDrafts", id),
        });
      }}
      approveClientDraft={(id: string) => {
        return approveClient({
          variables: { id },
          refetchQueries,
          update: updateFunction("clientDrafts", id),
        });
      }}
      rejectClientDraft={(id: string) => {
        return rejectClient({
          variables: { id },
          refetchQueries,
          update: updateFunction("clientDrafts", id),
        });
      }}
      resetClientDraft={(id: string) => {
        return resetClient({
          variables: { id },
          refetchQueries,
          update: updateFunction("clientDrafts", id),
        });
      }}
      approveEmployeeDraft={(id: string) => {
        return approveEmployee({
          variables: { id },
          refetchQueries,
          update: updateFunction("employeeDrafts", id),
        });
      }}
      rejectEmployeeDraft={(id: string) => {
        return rejectEmployee({
          variables: { id },
          refetchQueries,
          update: updateFunction("employeeDrafts", id),
        });
      }}
      resetEmployeeDraft={(id: string) => {
        return resetEmployee({
          variables: { id },
          refetchQueries,
          update: updateFunction("employeeDrafts", id),
        });
      }}
      approveReferenceDraft={(id: string) => {
        return approveReference({
          variables: { id },
          refetchQueries,
          update: updateFunction("referenceDrafts", id),
        });
      }}
      rejectReferenceDraft={(id: string) => {
        return rejectReference({
          variables: { id },
          refetchQueries,
          update: updateFunction("referenceDrafts", id),
        });
      }}
      resetReferenceDraft={(id: string) => {
        return resetReference({
          variables: { id },
          refetchQueries,
          update: updateFunction("referenceDrafts", id),
        });
      }}
      approveTeamMemberDraft={(id: string) => {
        return approveTeamMember({
          variables: { id },
          refetchQueries,
          update: updateFunction("projectTeamMemberDrafts", id),
        });
      }}
      rejectTeamMemberDraft={(id: string) => {
        return rejectTeamMember({
          variables: { id },
          refetchQueries,
          update: updateFunction("projectTeamMemberDrafts", id),
        });
      }}
      resetTeamMemberDraft={(id: string) => {
        return resetTeamMember({
          variables: { id },
          refetchQueries,
          update: updateFunction("projectTeamMemberDrafts", id),
        });
      }}
    />
  );
};

export default DraftsShell;
