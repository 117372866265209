import React, { useState, JSX } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  Observable,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { getApiUrl } from "helpers/apiUrl";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { appsignal } from "initializers/appsignal";

// HELPER FUNCTIONS

// MAIN COMPONENT / SETUP APOLLO

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        allOnepagers: {
          ...offsetLimitPagination(),
          keyArgs: ["onepagerType"],
        },
      },
    },
  },
});
const httpLink = new HttpLink({
  uri: `${getApiUrl()}/api/graphql`,
  credentials: "same-origin",
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
  },
  attempts: (count, operation, error) => {
    console.log(`GQL failed. Retry attempt: ${count}`);
    console.log(operation, error);
    console.log("Error status code:", error.statusCode);
    if (error.statusCode == 413) {
      return false;
    }
    return !!error && count < 3;
  },
});

const appsignalLogLink = (username) =>
  new ApolloLink((operation, forward) => {
    appsignal.addBreadcrumb({
      action: operation.operationName,
      category: "GraphQL",
      metadata: { ...operation.variables, currentUser: username },
    });

    return forward(operation);
  });

const ConfiguredApolloProvider = ({
  username,
  token,
  children,
}: any): JSX.Element => {
  const request = async (operation: any) => {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  };

  // This code comes from the apollo migration guide
  const requestLink = new ApolloLink(
    (operation, forward: any) =>
      new Observable((observer) => {
        let handle: any;
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      }),
  );

  const [, setDummy] = useState(null);

  // In order to fix caching, we needed to set `query` and `watchQuery` to cache-and-network
  // https://www.apollographql.com/docs/react/api/react-apollo/#optionsfetchpolicy
  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([
      retryLink,
      onError(({ networkError, graphQLErrors }) => {
        if (networkError) {
          console.log("[GraphQl network error]", networkError, graphQLErrors);
          if ((networkError as any)?.statusCode == 413) {
            networkError.message = "Error413";
          }
        } else if (graphQLErrors) {
          console.log("[GraphQl errors]", graphQLErrors);
        } else {
          // React error boundaries can't catch errors from asynchronous code.
          // Using the setState callback of useState here makes sure the error gets thrown in the render loop of React.
          // This way, the error boundary is able to catch the error.
          // https://github.com/facebook/react/issues/14981#issuecomment-543160354
          //
          // Without this hack, apollo errors freeze the app or cause other weird behavior. This way, errors are
          // immediately visible and will also show up in appsignal.
          setDummy(() => {
            console.log("[GraphQl general error]", graphQLErrors);
            throw graphQLErrors;
          });
        }
      }),
      requestLink,
      appsignalLogLink(username),
      httpLink,
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network" as any,
      },
      query: {
        fetchPolicy: "cache-and-network" as any,
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ConfiguredApolloProvider;
